import dragula from "dragula";
import { initializeAutocomplete } from "./functions.js";
import initTabs from "javascripts/utils";
import Rails from "@rails/ujs";

function reverseChildren(parent) {
  for (var i = 1; i < parent.childNodes.length; i++) {
    parent.insertBefore(parent.childNodes[i], parent.firstChild);
  }
}

$(document).on("turbolinks:load", function () {
  $("#contact-entrepreneur .new_message").submit(function () {
    $("#contact-entrepreneur-response").html("שולח...");
  });

  $("#contact-reelook .new_message").submit(function () {
    $("#contact-us-response").html("שולח...");
  });

  $("#contact-entrepreneur-order .new_message").submit(function () {
    $("#contact-entrepreneur-response").html("שולח...");
  });

  // var exampleModal = document.getElementById('share-project');
  // exampleModal.addEventListener('show.bs.modal', function (event) {
  document.getElementById('share-project').addEventListener('show.bs.modal', function (event) {
  //$("#share-project").on('show.bs.modal', function (event) {
    // Button that triggered the modal
    var origElem = event.relatedTarget;
    // Extract info from data-bs-* attributes
    var replaceHref = origElem.getAttribute('data-bs-href');
    var replaceVal = origElem.getAttribute('data-bs-val');
    var replaceTxt1 = origElem.getAttribute('data-bs-txt1');
    var replaceTxt2 = origElem.getAttribute('data-bs-txt2');
    // If necessary, you could initiate an AJAX request here
    // and then do the updating in a callback.
    //
    // Update the modal's content.
    var modalTxt1 = this.querySelector('#share-text1-replace');
    var modalTxt2 = this.querySelector('#share-text2-replace');
    var modalVal = this.querySelector('#share-value-replace');
    var modalHrefs = this.querySelectorAll('.share-href-replace');

    modalTxt1.textContent = replaceTxt1 ? replaceTxt1 : "שיתוף פרויקט";
    modalTxt2.textContent = replaceTxt2 ? replaceTxt2 : "באפשרותך לשתף את הפרויקט בדרכים הבאות :";
    modalVal.value = replaceVal;
    //modalHref.href = modalHref.href.replace("href-replace", replaceHref);
    for (var i=0; i < modalHrefs.length; i++) {
      modalHrefs[i].href = modalHrefs[i].href.replace("href-replace", replaceHref);
    }
  });

  $(".copy-to-clipboard").on("click", function () {
    var copyText = document.querySelector(".modal.show .project-url");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    alert("הטקסט הועתק!");
  });


  

  $(".tab-subscribe-dialog").on("click", function () {
    $(this).parents(".tab-newpages.tab-content").parent().addClass("hidden");
    $("#comparison-tab").removeClass("active");
    $(this).parents(".tab-pane").removeClass("active");
  });

  $("#record-tab").on("click", function () {
    //var x = screen.width/2 - 600/2;
    //var y = screen.height/2 - 600/2;
    var winFeatures = 'menubar=no,screenX=0,screenY=0,top=50px,left=50px,width=100,height=100,location=no,status=no,toolbar=no,scrollbars=no';
    var winName = 'window';
    var win = window.open("https://reelook-public.s3.eu-west-1.amazonaws.com/screen_record/index.html",winName, winFeatures);    
    win.resizeBy(600, 600);
  });

  /*
  $(".header .btn-close").each(function () {
    $(this).css({ background: "red", filter: "unset" });

    const el = $(this).closest(".tab-pane");

    console.log(el[0], el.attr("id"));

  });

  */

  // initializing nav-tabs
  const mainTabElem = document.getElementById("main-tab");
  initTabs({
    selector: "nav.navbar [role='tab']",
    getCloseBTN: (container) => {
      if (container && container !== mainTabElem)
        return $(
          container.getAttribute("data-bs-target") + " .btn-close"
        ).first();
    },
    onClose: () => {
      bootstrap.Tab.getInstance(mainTabElem).show();
    },
  });

  const compareTab = document.getElementById("comparison-tab");
  let compareTabHref = "#";

  function changeTabHref(event) {
    const tempHref = compareTabHref;
    compareTabHref = event.target.getAttribute("href");
    event.target.setAttribute("href", tempHref);
  }

  let isDragging = false;

  function dropHandler(container) {
    isDragging = false;
    container.classList.remove("flip");
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
    reverseChildren(container);
  }

  // improved drag&drop mechanism for "compare apartments"

  let drake;
  // For New Drag Comment Existing Code

  // const listContainer = document.querySelector(
  //   "#compare-apartments-container .apartments-list"
  // );
  
  // For New Drag Comment Existing Code

  const config = { childList: true };
  const listObserver = new MutationObserver(mutationHandler);

  function mutationHandler() {
    if (drake) drake.destroy();
    drake = dragula([listContainer], {
      direction: "horizontal",
      moves: function (el, container, handle) {
        return handle.tagName !== "A";
      },
    });

    drake.on("drag", function () {
      // because "drag" changes the DOM tree, we need to stop listening for DOM changes
      listObserver.disconnect();
      isDragging = true;
      listContainer.classList.add("flip");
      reverseChildren(listContainer);
    });

    drake.on("dragend", () => {
      // after we finish dragging the cards, we listen for changes.
      // this happens before the DOM tree changes, so at the end
      // another "Mutation" event is fired. This is good because
      // the list-container is correctly updated and ready for feature drags
      listObserver.observe(listContainer, config);
      if (isDragging) dropHandler(listContainer);
    });

    drake.on("drop", () => {
      dropHandler(listContainer);
    });
  }

  if (compareTab) {
    // start listening for DOM changes, just before the comparison tab appears
    compareTab.addEventListener("show.bs.tab", function (e) {
      listObserver.observe(listContainer, config);
    });

    compareTab.addEventListener("shown.bs.tab", function (e) {
      changeTabHref(e);
    });

    // hiding the comparison table after closing the panel to prevent the "modern" scrollbars to flicker when it reappears
    compareTab.addEventListener("hidden.bs.tab", (e) => {
      if (drake) drake.destroy();
      listObserver.disconnect();
      changeTabHref(e);
    });
  }

  $(".close-icon").on("click", function () {
    $(".close-icon").css("display", "none");
    $(".search-form .search-filter").val("");
  });

  /* search filter blur */
  $(".search-form .search-filter").on("focus, click ", function () {
    $(".close-icon").css("display", "block");
    if ($("#map").length > 0) {
      $(this).parent(".search-mob").addClass("focus");
    }
    // $(".tab-content").addClass("body-overlay");

    if ($(this).parent(".search-mob").hasClass("focus")) {
      $(
        "ul.ui-autocomplete.ui-menu.ui-widget.ui-widget-content.ui-corner-all"
      ).addClass("left15");
    }
    $(
      "ul.ui-autocomplete.ui-menu.ui-widget.ui-widget-content.ui-corner-all"
    ).css("width", $(this).outerWidth());
  });

  $(".col-md-8").on("click", function () {
    if ($("#map").length > 0) {
      $(".search-form .search-filter")
        .parent(".search-mob")
        .removeClass("focus");
    }

    // $(".tab-content").removeClass("body-overlay");

    if ($(".search-form .search-filter").val().length == 0) {
      $(".close-icon").css("display", "none");
    }

    $(
      "ul.ui-autocomplete.ui-menu.ui-widget.ui-widget-content.ui-corner-all"
    ).removeClass("left15");
    $(
      "ul.ui-autocomplete.ui-menu.ui-widget.ui-widget-content.ui-corner-all"
    ).css("width", $(".search-form .search-filter").outerWidth());
  });

  /* toggle menu */

  var theToggle = document.getElementById("toggle");

  if (theToggle) {
    theToggle.onclick = function () {
      toggleClass(this, "on");
      return false;
    };
  }

  // based on Todd Motto functions
  // https://toddmotto.com/labs/reusable-js/

  // hasClass
  function hasClass(elem, className) {
    return new RegExp(" " + className + " ").test(" " + elem.className + " ");
  }
  // addClass
  function addClass(elem, className) {
    if (!hasClass(elem, className)) {
      elem.className += " " + className;
    }
  }
  // removeClass
  function removeClass(elem, className) {
    var newClass = " " + elem.className.replace(/[\t\r\n]/g, " ") + " ";
    if (hasClass(elem, className)) {
      while (newClass.indexOf(" " + className + " ") >= 0) {
        newClass = newClass.replace(" " + className + " ", " ");
      }
      elem.className = newClass.replace(/^\s+|\s+$/g, "");
    }
  }
  // toggleClass
  function toggleClass(elem, className) {
    var newClass = " " + elem.className.replace(/[\t\r\n]/g, " ") + " ";
    if (hasClass(elem, className)) {
      while (newClass.indexOf(" " + className + " ") >= 0) {
        newClass = newClass.replace(" " + className + " ", " ");
      }
      elem.className = newClass.replace(/^\s+|\s+$/g, "");
    } else {
      elem.className += " " + className;
    }
  }

  $("#toggle").on("click", function () {
    if ($(this).parents(".dropdown-custom").find(".on").length) {
      $(this).parents("nav").addClass("cf");
    } else {
      $(this).parents("nav").removeClass("cf");
    }
  });

  // clear filter button
  $(".clear-filters").on("click", function () {
    const filter = $(this).parent().siblings();
    const form = $(this).closest("form")[0];

    filter.find(".pop-slider")[0].noUiSlider.reset();
    filter.find(".floor-slider")[0].noUiSlider.reset();
    filter.find(".area-slider")[0].noUiSlider.reset();
    filter.find(".rooms-slider")[0].noUiSlider.reset();
    const price = filter.find(".price-slider")[0];

    price.noUiSlider.reset();

    $(filter).find("input[type=checkbox]:checked").prop("checked", false);

    console.log($(form).serialize());
    Rails.fire(form, "submit");
  });

  // panel background
  $(".panel-heading a").on("click", function () {
    if (!$(this).parents(".panel-heading").hasClass("active")) {
      $(this).parents(".panel").addClass("active");
      $(this).parents(".panel").siblings().removeClass("active");
    } else {
      $(this).parents(".panel").removeClass("active");
    }
  });

  $("#home-search-project").on("click", function () {
    var url = "/projects";

    Turbolinks.visit(url);
  });

  // if ($(window).width() <= 1020) {
  //   initializeAutocomplete("autocomplete_search_address_mobile");
  // } else {
     initializeAutocomplete("autocomplete_search_address");
  // }
});
