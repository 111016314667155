import Sketchfab from "@sketchfab/viewer-api";

$(document).on('turbolinks:load', function () {

    var iframe = document.getElementById('api-frame');
    var isShow3DMap = true;
    var isShowBuildings = true;
    var myAllMaterials = {};
    var myMaterials = {};
    var myEmptyNodes = {};
    var myDiraNodes = {};
    var mygraph = {};
    var origCameraPos = [];
    var origCameraTarget = [];
    var origFov = null;
    var greenColor = [0.1411764706, 0.7019607843, 0.2941176471];
    //var blueColor = [0, 0.7058823529, 0.8980392157];
    var blueColor = greenColor;    
    //var orangeColor = [0.9490196078, 0.4156862745, 0.1294117647];
    var orangeColor = greenColor;    
    var emptyEmitColor = [0, 0, 0];
    var emptyAlbedoColor = [0.72, 0.72, 0.72];
    //var yellowColor = [0.9960784314, 0.862745098, 0];
    var yellowColor = emptyEmitColor;
    //var redColor = [0.862745098, 0, 0];
    var redColor = emptyEmitColor;
    var scan3DId;
    var scan2DId;
    var map2DId;
    var background3DId;
    const cameraConsJsonTrue = JSON.parse('{"usePanConstraints":true}');
    const cameraConsJsonFalse = JSON.parse('{"usePanConstraints":false}');
    var cameraMov;
    var selectedApp;
    var annotationCount;
    var appsStatusObj = {};
    var envAnimationUID;
    var poiAnimationUID;
    var minDistanceToBuilding = 3000;
    var maxDistanceToSurround = 18000;
    var minDistanceToActivateCons = 2500;
    var maxDistanceToActivateCons = 18500;
    var minHightToActivateCons = 10;
    var isEnvAnimationCurr = false;
    let apartmentsCount = 0;
    let videoTextureUrl;
    let adv01Matid = 0;
    let adv02Matid = 0;
    let advTexturId;
    let emptyMatId = 0;
    let greenMatId = 0;
    let greenMat;
    let adv01Mat;
    let selectedMatId = 0;
    let selectedMat;
    let highLightMat;
    let selectedNode;
    let highLightNode;
    let isProjOverMatLimit = false;
    let is_proj_mobile = false;
    let prevMatId;
    let panoMatId = 0;
    let panoMat;
    let origPanoMat;
       


    if (iframe != null) {

        var proj_3d_params = JSON.parse($("#proj_3d_model").val());
        
        if(proj_3d_params.hasOwnProperty('proj_3d_model_url')){
            var proj_3d_model_url_parts = proj_3d_params["proj_3d_model_url"].split("-");
        }
        if(proj_3d_params.hasOwnProperty('proj_3d_model_url_mobile')){
            var proj_3d_model_url_mobile_parts = proj_3d_params["proj_3d_model_url_mobile"].split("-");
        }
        if(proj_3d_params.hasOwnProperty('proj_apartments_number')){
            apartmentsCount = proj_3d_params["proj_apartments_number"];
        }
        if(proj_3d_params.hasOwnProperty('is_proj_over_mat_limit')){
            isProjOverMatLimit = proj_3d_params["is_proj_over_mat_limit"];
        }
        if(proj_3d_params.hasOwnProperty('is_proj_mobile')){
            is_proj_mobile = proj_3d_params["is_proj_mobile"];
        }
        if(proj_3d_params.hasOwnProperty('proj_adv_vid_01_link')){
            videoTextureUrl = proj_3d_params["proj_adv_vid_01_link"];
        }

        //var proj_3d_model_url_parts = $("#proj_3d_model").val().split("-");
        if (Navigator.deviceMemory > 5) 
            var uid = proj_3d_model_url_parts[proj_3d_model_url_parts.length - 1];
        else
            var uid = proj_3d_model_url_parts[proj_3d_model_url_parts.length - 1];

        // By default, the latest version of the viewer API will be used.
        var client = new Sketchfab(iframe);



        if (is_proj_mobile) {

            client.init(uid, {
                autostart: 1,
                ui_help: 0,
                ui_controls: 0,
                ui_fullscreen: 0,
                ui_general_controls: 0,
                ui_hint: 0,
                ui_settings: 0,
                ui_vr: 0,
                dof_circle: 0,
                preload: 1,
                ui_stop: 0,
                ui_fullscreen: 0,
                pixel_budget: 0,
                camera: 0,
                animation_autoplay: 0,
                ui_animations: 0,
                annotations_visible: 0,
                ui_annotations: 0,
                autospin: 0, 
                ui_infos: 0,
                ui_inspector: 0,
                ui_loading: 0,
                ui_sound: 0,
                ui_watermark_link: 0,
                ui_ar: 0,
                ui_ar_help: 0,
                ui_ar_qrcode: 0,
                ui_watermark: 0,
                max_texture_size: 1024,
                
                //https://help.sketchfab.com/hc/en-us/articles/201766675-Improving-Viewer-Performance
                //if (Navigator.deviceMemory > 4) {            
                merge_materials: 1,
                graph_optimizer: 1,
                material_packing: 1,
            
                //only in dev
                api_log: 1,

                success: function onSuccess(api) {
                    api.start();                    

                    api.addEventListener('viewerready', function () {
                        // API is ready to use
                        // Insert your code here
                        console.log('Viewer is ready');                    

                        api.getCameraLookAt(function (err, camera) {
                            origCameraPos = camera.position; // [x, y, z]
                            origCameraTarget = camera.target; // [x, y, z]
                            window.console.log(camera.position); // [x, y, z]
                            window.console.log(camera.position3D);
                            window.console.log(camera.target); // [x, y, z]                        
                        });

                        api.getFov(function (err, fov) {
                            origFov = fov;
                            console.log('origFov is ready');
                        });

                        api.getAnimations(function(err, animations) {
                            if (!err) {
                                if ((animations != undefined ) && (animations.length > 0))
                                {
                                    animations.map(function (anim, i) {
                                    if (anim[1] == "ICONS")
                                        poiAnimationUID = anim[0];
                                    else if (anim[1] == "SVIVA")
                                        envAnimationUID = anim[0];
                                    });

                                    //if (poiAnimationUID != undefined)
                                    //    setPoiAnimation();
                                    //else if (envAnimationUID != undefined)
                                    if (envAnimationUID != undefined)
                                        setEnvAnimation();
                                }
                            }
                        });

                        api.setCameraConstraints(cameraConsJsonFalse, function(err) {
                            api.setEnableCameraConstraints(true, { preventCameraConstraintsFocus: false }, function (err) {
                                if (!err) {
                                    window.console.log('Camera pan constraints disable');
                                }
                            });
                        });                     
                                        
                        //disable waiting gif while iframe is loading
                        //$("#hidemiddle-container").addClass("hidemiddle-container-anim");
                        $("#hidemiddle-container").remove();

                    });

                    $(".screenshot3d").click(function (event) {                          
                        
                        console.log(navigator.deviceMemory);
                        api.getScreenShot('image/png', function (err, result) {
                            var anchor = document.createElement('a');
                            anchor.href = result;
                            anchor.download = 'screenshot.png';
                            anchor.style.display = 'none';
                            document.body.appendChild(anchor);
                            anchor.click();
                            document.body.removeChild(anchor);
                        });
                    });

                    $(".resetcam").click(function (event) {
                        //api.setEnableCameraConstraints(false, { preventCameraConstraintsFocus: false })
                        api.setCameraLookAt(origCameraPos, origCameraTarget, 3); // [x, y, z]
                        //api.unselectAnnotation(0);
                        // api.hideAnnotationTooltips(function(err) {
                        //     if (!err) {
                        //         window.console.log('Hiding annotation tooltip');
                        //     }
                        // });                   

                    });

                    $(".toggle-3dbuildOn").click(function (event) {
                        playEnvAnimation();
                        $(".toggle-3dbuildOn").css("display", "none")
                        $(".toggle-3dbuildOff").css("display", "flex")                 
                    });

                    $(".toggle-3dbuildOff").click(function (event) {
                        playEnvAnimation();
                        $(".toggle-3dbuildOff").css("display", "none")
                        $(".toggle-3dbuildOn").css("display", "flex")                 
                    });


                    // $(".toggle-map").click(function (event) {
                    //     //setEnvAnimation();
                    //     playEnvAnimation();
                    // });

                    function playEnvAnimation() {
                        if (isShow3DMap) {
                                // api.getAnnotation(0, function(err, information) {
                                //     if (!err) {
                                //         window.console.log(information);
                                //     }
                                // });
                                // api.setSpeed(1, function(err) {
                                //     api.play();
                                //     isShow3DMap = false;
                                // });
                                //api.hideAnnotation(0);                        
                                // api.setAnnotationsTexture(
                                //     {url:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC",
                                //     padding: 2,
                                //     iconSize: 48,
                                //     colNumber: 10});
                                if (envAnimationUID != undefined){
                                    api.setSpeed(1, function(err) {
                                        isShowBuildings = false;
                                        window.console.log(isShowBuildings);
                                        //api.seekTo(0.01);
                                        api.play();                                
                                    });
                                }
                                else {
                                    api.hide(scan3DId);
                                    api.hide(scan2DId);
                                    setBackground(false);
                                    api.show(map2DId);
                                    isShow3DMap = false;
                                }
                        } else {
                            // api.setSpeed(-1, function(err) {
                            //     api.play();
                            //     isShow3DMap = true;
                            // });
                            //api.showAnnotation(0);
                            api.hide(map2DId);                        
                            api.show(scan3DId);
                            api.show(scan2DId);                                               
                            isShow3DMap = true;                                                        

                            if (envAnimationUID != undefined){
                                api.setSpeed(-1, function(err) {
                                    isShowBuildings = true;
                                    window.console.log(isShowBuildings);
                                    //api.seekTo(0.99);
                                    api.play();                                
                                });
                            }                   
                            setBackground(true);     
                        } 
                    }
                    
                    api.addEventListener('animationEnded', function() {
                        if (isEnvAnimationCurr) {
                            window.console.log('Animation ended');
                            if (isShowBuildings){
                                api.seekTo(0.01);                               
                            }
                            else{
                                api.seekTo(0.99);
                                api.hide(scan3DId);
                                api.hide(scan2DId);
                                setBackground(false);
                                api.show(map2DId);
                                isShow3DMap = false;     
                            } 
                            //setPoiAnimation();                       
                        }                  
                    });

                    $(".toggle-surroundings").click(function (event) {

                        if ($("#project-3d-tour").length > 0){ 
                            $("#project-3d-tour").show();
                        }
                        else{                    
                            api.setEnableCameraConstraints(false, { preventCameraConstraintsFocus: false })                    
                            api.setCameraLookAt([origCameraTarget[0],(origCameraTarget[1]-5000),maxDistanceToSurround],
                                origCameraTarget, 4); // [x, y, z]
                        }
                        
                        
                            //api.setCameraLookAt([0,-2000,40000],[0,0,0], 4);
                        // api.showAnnotationTooltips(function(err) {
                        //     if (!err) {
                        //         window.console.log('Showing annotation tooltip');
                        //         api.gotoAnnotation(0,  {preventCameraAnimation: true, preventCameraMove: false}, function(err, index) {
                        //             if (!err) {
                        //                 window.console.log('Going to annotation', index + 1);
                        //            }
                        //         });
                        //     }
                        // });             
                    });
                    

                    function setBackground(isShown) {
                        //if (panoMatId != 0) {
                            if (isShown) {
                                //panoMat.channels.EmitColor.enable = true;
                                //panoMat.channels.DiffusePBR.factor = 0.5;
                                api.setBackground({color: [0.9, 0.9, 0.9]}, function() {
                                    window.console.log('Background changed white');
                                    api.show(background3DId);
                                });
                                   
                            } else {
                                //panoMat.channels.EmitColor.enable = false;
                                //panoMat.channels.DiffusePBR.factor = 0;
                                api.setBackground({color: [0, 0, 0]}, function() {
                                    window.console.log('Background changed black');
                                    api.hide(background3DId);
                                });
                            }

                            //api.setMaterial(panoMat);
                        //}
                    }


                    function setEnvAnimation() {
                        api.pause();
                        api.setCurrentAnimationByUID(envAnimationUID, function(err) {
                            if (!err) {
                                window.console.log('Set animation track to', envAnimationUID);
                                api.setCycleMode('one', function(err) {
                                    if (!err) {
                                        window.console.log('Set animation cycle mode one');
                                        isEnvAnimationCurr = true;
                                        //playEnvAnimation();
                                    }
                                });
                            }
                        }); 

                        return isEnvAnimationCurr;
                    }


                },
                error: function onError() {
                    console.log('Viewer error');
                }
            });

        }

        else {
            
            let apartments = JSON.parse($("#project-apartments")[0].dataset.apartments);
       
            let lastFilterAppStatusString = "";
            //let appStatusArrayInit = [];
            let appStatusInitString = "";

            Object.keys(apartments).forEach(function (apt_id) {
                //appStatusArrayInit.push(apt_id + ":" + JSON.parse(apartments[parseInt(apt_id)]).status);
                appStatusInitString += (apt_id + ":" + JSON.parse(apartments[apt_id]).status + ',')
            });

            appStatusInitString = appStatusInitString.slice(0, -1);

            var appStatusInitArray = appStatusInitString.split(',');
            
            var appsStatusInitObj = {};
            appStatusInitArray.forEach(function (appStatus) {
                appsStatusInitObj[appStatus.split(':')[0]] = appStatus.split(':')[1];
            });
            
            client.init(uid, {
                autostart: 1,
                ui_help: 0,
                ui_controls: 0,
                ui_fullscreen: 0,
                ui_general_controls: 0,
                ui_hint: 0,
                ui_settings: 0,
                ui_vr: 0,
                dof_circle: 0,
                preload: 1,
                ui_stop: 0,
                ui_fullscreen: 0,
                pixel_budget: 0,
                camera: 0,
                animation_autoplay: 0,
                ui_animations: 0,
                annotations_visible: 0,
                ui_annotations: 0,
                autospin: 0, 
                ui_infos: 0,
                ui_inspector: 0,
                ui_loading: 0,
                ui_sound: 0,
                ui_watermark_link: 0,
                ui_ar: 0,
                ui_ar_help: 0,
                ui_ar_qrcode: 0,
                ui_watermark: 0,
                //max_texture_size: 512,
                
                //https://help.sketchfab.com/hc/en-us/articles/201766675-Improving-Viewer-Performance
                //if (Navigator.deviceMemory > 4) {            
                merge_materials: 0,
                graph_optimizer: 0,
                material_packing: 0,
            
                //only in dev
                api_log: 1,

                success: function onSuccess(api) {
                    api.start();

                    window.requestFilterAppsByStatus = function requestFilterAppsByStatus(appStatusFilterString, enableCons) {
                        lastFilterAppStatusString = appStatusFilterString;
                        
                        // if (enableCons) {
                        //     api.setCameraConstraints(cameraConsJson, function(err) {
                        //         api.setEnableCameraConstraints(true, { preventCameraConstraintsFocus: false }, function (err) {
                        //             if (!err) {
                        //                 window.console.log('Camera constraints enabled');
                        //             }
                        //         });
                        //     });                        
                        // }
                        // else {
                        //     api.setEnableCameraConstraints(false, { preventCameraConstraintsFocus: false }, function (err) {
                        //         if (!err) {
                        //             window.console.log('Camera constraints disabled');
                        //         }
                        //     });
                        // }
                        if (enableCons) {
                            api.getCameraLookAt(function (err, camera) {                        
                                var dist = getLength(addVectors(camera.position, scalarMultiply(origCameraTarget, -1))); 
                                if (dist > 10000) 
                                    api.setCameraLookAt(
                                        calcCloseViewCamPoint(origCameraTarget, camera.position,minDistanceToBuilding),
                                        origCameraTarget,
                                        2); 
                            }); 
                        }

                                    

                        var appStatusArray = appStatusFilterString.split(',');

                        appsStatusObj = {};
                        appStatusArray.forEach(function (appStatus) {
                            appsStatusObj[appStatus.split(':')[0]] = appStatus.split(':')[1];
                        });
                        
                        if (!isProjOverMatLimit) {
                            Object.keys(myMaterials).forEach(function (mKey) {
                                var mValue = myMaterials[mKey];
                                switch (appsStatusObj[mKey]) {
                                    case "משוריין":
                                        mValue.channels.Opacity.enable = true;
                                        mValue.channels.Opacity.factor = 0.2;
                                        mValue.channels.EmitColor.factor = 2;
                                        mValue.channels.EmitColor.enable = true;
                                        mValue.channels.EmitColor.color = yellowColor;
                                        mValue.channels.AlbedoPBR.color = yellowColor;
                                        break;
                                    case "למכירה":
                                        mValue.channels.Opacity.enable = true;
                                        mValue.channels.Opacity.factor = 0.2;
                                        mValue.channels.EmitColor.factor = 2;
                                        mValue.channels.EmitColor.enable = true;
                                        mValue.channels.EmitColor.color = greenColor;
                                        mValue.channels.AlbedoPBR.color = greenColor;
                                        break;
                                    case "נמכר":
                                        mValue.channels.Opacity.enable = true;
                                        mValue.channels.Opacity.factor = 0.2;
                                        mValue.channels.EmitColor.factor = 2;
                                        mValue.channels.EmitColor.enable = true;
                                        mValue.channels.EmitColor.color = redColor;
                                        mValue.channels.AlbedoPBR.color = redColor;
                                        break;
                                    case "Promotion":
                                        mValue.channels.Opacity.enable = true;
                                        mValue.channels.Opacity.factor = 0.2;
                                        mValue.channels.EmitColor.factor = 2;
                                        mValue.channels.EmitColor.enable = true;
                                        mValue.channels.EmitColor.color = blueColor;
                                        mValue.channels.AlbedoPBR.color = blueColor;
                                        break;
                                    case "Presale":
                                        mValue.channels.Opacity.enable = true;
                                        mValue.channels.Opacity.factor = 0.2;
                                        mValue.channels.EmitColor.factor = 2;
                                        mValue.channels.EmitColor.enable = true;
                                        mValue.channels.EmitColor.color = orangeColor;
                                        mValue.channels.AlbedoPBR.color = orangeColor;
                                        break;
                                    default:
                                        mValue.channels.Opacity.enable = true;
                                        mValue.channels.Opacity.factor = 0;
                                        mValue.channels.EmitColor.factor = 0;
                                        mValue.channels.EmitColor.enable = false;
                                        mValue.channels.EmitColor.color = emptyEmitColor;
                                        mValue.channels.AlbedoPBR.color = emptyAlbedoColor;
                                }
                                api.setMaterial(mValue);

                                // $(".toggle-lightAppsOn").css("display", "none");
                                // $(".toggle-lightAppsOff").css("display", "block");
                                // $(".toggle-3dbuildOn").css("display", "none");
                                // $(".toggle-3dbuildOff").css("display", "block");
                            });
                        }
                        else {
                            Object.keys(myDiraNodes).forEach(function (mDiraKey) {
                                var nodeValue = myDiraNodes[mDiraKey];                            
                                switch (appsStatusObj[mDiraKey]) {
                                    case ("למכירה"):
                                    case ("Promotion"):
                                    case ("Presale"):
                                        api.assignMaterial(nodeValue, greenMatId, function(err) {
                                            // if (!err) {
                                            //     window.console.log('Material assigned '+ mDiraKey + ' ' + greenMatId);
                                            // }
                                            // else
                                            //     window.console.log(err + ' ' +greenMatId);
                                        });
                                        break;                                                      
                                    default:
                                        api.assignMaterial(nodeValue, emptyMatId, function(err) {
                                            // if (!err) {
                                            //     window.console.log('Material assigned '+ mDiraKey + ' ' + emptyMatId);
                                            // }
                                            // else
                                            //     window.console.log(err + ' ' +emptyMatId);
                                        });                                    
                                }
                            });
                            api.refreshDraw();
                        }
                    };

                    api.addEventListener('viewerready', function () {
                        // API is ready to use
                        // Insert your code here
                        console.log('Viewer is ready');                    

                        api.getCameraLookAt(function (err, camera) {
                            origCameraPos = camera.position; // [x, y, z]
                            origCameraTarget = camera.target; // [x, y, z]
                            window.console.log(camera.position); // [x, y, z]
                            window.console.log(camera.position3D);
                            window.console.log(camera.target); // [x, y, z]                        
                        });

                        api.getFov(function (err, fov) {
                            origFov = fov;
                            console.log('origFov is ready');
                        });

                        api.setHighlightOptions({
                            outlineWidth: 2,
                            outlineColor: [1, 1, 1],
                            outlineDuration: 2,                        
                        }, function() {
                            window.console.log('Set highlight options');
                        });

                        

                        //  api.setTextureQuality('ld', function(err) {
                        //      if (!err) {
                        //          window.console.log('Texture quality set to ld');
                        //      }
                        //  });

                        // api.setPostProcessing({
                        //     enable: false                        
                        //     }, function() {
                        //         window.console.log('Post-processing filters set');
                        // });

                        // api.getAnnotationList(function(err, annotations) {
                        //     annotationCount = annotations.length;
                        //     if (!err) {
                        //         // annotations.map(function (annotation, i) {
                        //         //     api.hideAnnotation(i, function(err, index) {
                        //         //         if (!err) {
                        //         //              window.console.log('Hiding annotation', index + 1);
                        //         //         }
                        //         //     });    
                        //         // });
                                
                        //         api.setAnnotationsTexture(
                        //             {
                        //                 url: "https://maps.gstatic.com/mapfiles/transparent.png",
                        //                 padding: 2,
                        //                 iconSize: 48,
                        //                 colNumber: 10,
                        //             },
                        //             function () {}
                        //         );
                        //      }
                        // });

                        // api.createMaterial({
                        //     channels: {
                        //         AlbedoPBR: {
                        //             color: emptyAlbedoColor
                        //         },
                        //         Opacity: {
                        //             enable: true,
                        //             factor: 0
                        //         },
                        //         EmitColor: {
                        //             enable: false,
                        //             color: emptyEmitColor,
                        //             factor: 0
                        //         }                             
                        //     }
                        // }, function(err, material) {
                        //     if (!err) {
                        //         //emptyMatId = material.stateSetID;
                        //         emptyMatId = material.id;
                        //         window.console.log(material);                            
                        //     }
                        // });

                        api.getAnimations(function(err, animations) {
                            if (!err) {
                                if ((animations != undefined ) && (animations.length > 0))
                                {
                                    animations.map(function (anim, i) {
                                    if (anim[1] == "ICONS")
                                        poiAnimationUID = anim[0];
                                    else if (anim[1] == "SVIVA")
                                        envAnimationUID = anim[0];
                                    });

                                    //if (poiAnimationUID != undefined)
                                    //    setPoiAnimation();
                                    //else if (envAnimationUID != undefined)
                                    if (envAnimationUID != undefined)
                                        setEnvAnimation();
                                }
                            }
                        });

                        api.getMaterialList(function (err, materials) {
                            
                            //materials.forEach(function (mat, i) {
                            if (!isProjOverMatLimit) {
                                for (const mat of materials) {                            
                                    if (mat.name.includes("Dira")) {
                                        if (mat.name.split('_').length == 2){
                                            myMaterials["A_" + mat.name.split('_')[1]] = mat;
                                        }
                                        else{
                                            myMaterials[mat.name.slice(mat.name.indexOf("_")+1)] = mat;
                                        }                                 
                                    }
                                    else if ((adv01Matid == 0) && (mat.name == "adv_01")) {
                                        adv01Matid = mat.id;
                                        adv01Mat = mat;                                    
                                    }
                                    else if ((adv02Matid == 0) && (mat.name == "adv_02")) {
                                        adv02Matid = mat.id;
                                    }
                                    else if ((panoMatId == 0) && (mat.name == "panorma")) {
                                        panoMatId = mat.id;
                                        panoMat = mat;
                                        origPanoMat = panoMat;                                    
                                    }

                                    //if (Object.keys(myMaterials).length == apartmentsCount)
                                    //    break;
                                }
                            }
                            else {
                                for (const mat of materials) {
                                    if ((emptyMatId == 0) && (mat.name == "empty")) {
                                        emptyMatId = mat.id;
                                    }
                                    else if ((adv01Matid == 0) && (mat.name == "adv_01")) {
                                        adv01Matid = mat.id;
                                        adv01Mat = mat;                                    
                                    }
                                    else if ((adv02Matid == 0) && (mat.name == "adv_02")) {
                                        adv02Matid = mat.id;
                                    }
                                    else if ((greenMatId == 0) && (mat.name == "green")) {
                                        greenMat = mat;
                                        greenMatId = mat.id;                                   
                                        greenMat.channels.Opacity.enable = true;
                                        greenMat.channels.Opacity.factor = 1;
                                        greenMat.channels.EmitColor.factor = 7;
                                        greenMat.channels.EmitColor.enable = true;
                                        api.createMaterial(greenMat,function(err, material) {
                                            if (!err) {
                                                //window.console.log(material);
                                                selectedMatId = material.id;
                                                selectedMat = material;
                                            }
                                        });
                                        api.createMaterial(greenMat,function(err, material) {
                                            if (!err) {
                                                //window.console.log(material);
                                                highLightMat = material;
                                            }
                                        });                                   
                                    }
                                    else if ((panoMatId == 0) && (mat.name == "panorma")) {
                                        panoMatId = mat.id;
                                        panoMat = mat; 
                                        origPanoMat = panoMat;                                 
                                    }
                                    
                                    //if ((emptyMatId != 0) && (greenMatId != 0))
                                    //    break;
                                }
                            }
                            
                            if (adv01Matid && videoTextureUrl) {                            
                                api.addVideoTexture(videoTextureUrl, {
                                    mute: true,
                                    loop: true
                                }, function (err, textureId) {                                
                                    advTexturId = textureId;
                                    // adv01Mat.channels.AlbedoPBR.enable = true;
                                    // adv01Mat.channels.AlbedoPBR.texture = {
                                    // uid: advTexturId
                                    // }; //Emission
                                    //adv01Mat.channels.AlbedoPBR.color = [1, 1, 1, 0];
                                    adv01Mat.channels.Opacity.factor = 0.95;
                                    adv01Mat.channels['DiffuseColor'].texture = {
                                    uid: advTexturId
                                    }; //Emission
                                    adv01Mat.channels['EmitColor'].enable = true;
                                    adv01Mat.channels['EmitColor'].factor = 1;
                                    adv01Mat.channels['EmitColor'].texture = {
                                    uid: advTexturId
                                    };
                                    api.setMaterial(adv01Mat); 
                                    window.console.log('Added video texture with UID', textureId);                                
                                });
                            }

                            api.getNodeMap(function (err, nodes) {
                                let splitname = [];
                                //Object.values(nodes).forEach(function (node, i) {
                                for (const node of Object.values(nodes)) {
                                    if (node.name != undefined) {
                                        if ((node.name.includes("_empty_0"))  && (Object.keys(myEmptyNodes).length != apartmentsCount)){ 
                                            let splitname1 = node.name.split('_');
                                            if (splitname1.length == 5){
                                                myEmptyNodes[node.instanceID] = "A_" + splitname1[2]; 
                                            } 
                                            else{
                                                myEmptyNodes[node.instanceID] = splitname1[2] + "_" + splitname1[3];  
                                            }
                                        } 
                                        else if  ((isProjOverMatLimit) && (node.name.includes("Dira_"))  && (Object.keys(myDiraNodes).length != apartmentsCount + 2)){ 
                                            let splitname2 = node.name.split('_');

                                            if (splitname2.length == 5){
                                                //myDiraNodes[node.instanceID] = "A_" + splitname[4]; 
                                                //myDiraNodes["A_" + splitname[4]] = node.instanceID;
                                                myDiraNodes["A_" + splitname2[2]] = node;
                                            } 
                                            else{
                                                //myDiraNodes[node.instanceID] = splitname[4] + "_" + splitname[5];  
                                                //myDiraNodes[splitname[4] + "_" + splitname[5]] = node.instanceID;
                                                myDiraNodes[splitname2[2] + "_" + splitname2[3]] = node;
                                                //myDiraNodes[splitname2[1] + "_" + splitname2[2]] = node;
                                            }
                                        }

                                        // if (node.name.includes("Adv_01")) {
                                        //     adv01id = node.instanceID;                                      
                                        // } 
                                        
                                        // if (node.name.includes("Adv_02")) {
                                        //     adv02id = node.instanceID;                                      
                                        // } 
                                                        
                                        if ((Object.keys(myEmptyNodes).length == apartmentsCount) && 
                                            ((Object.keys(myDiraNodes).length == apartmentsCount + 2) || (!isProjOverMatLimit)))
                                        break;                            
                                    }
                                }
                                
                                api.getSceneGraph(function (err, graph) {
                                    mygraph = graph;
                                    graph.children[0].children[0].children.map(function (grap, i) {
                                        if (grap.name.includes("3DSCAN_Group")) {
                                            scan3DId = grap.instanceID;
                                            //api.hide(scan3DId);
                                        }
                                        else if (grap.name.includes("2DSCAN_Group")){
                                            scan2DId = grap.instanceID;
                                            //api.hide(scan2DId);
                                        }
                                        else if (grap.name.includes("2DMAP_Group")) {
                                            map2DId = grap.instanceID;
                                            api.hide(map2DId);
                                        }
                                        else if (grap.name.includes("3DREKA") || grap.name.includes("Pano_Back")) 
                                            background3DId = grap.instanceID;

                                    });
                                    mygraph={};
                                });

                                
                                



                                // api.getSceneGraph(function (err, graph) {
                                //     mygraph = graph;
                                //     graph.children.map(function (grap, i) {
                                //         if (i.name != undefined) {

                                //         }
                                //         if (grap.name.includes("3DSCAN_Group")) 
                                //             scan3DId = grap.instanceID;
                                //         else if (grap.name.includes("2DSCAN_Group")) 
                                //             scan2DId = grap.instanceID;
                                //         else if (grap.name.includes("2DMAP_Group")) {
                                //             map2DId = grap.instanceID;
                                //             api.hide(map2DId);
                                //         }
                                //         else if (grap.name.includes("3DREKA")) 
                                //             background3DId = grap.instanceID;

                                //     });
                                // });
                            
                                requestFilterAppsByStatus(appStatusInitString, false);
                                
                            });



                        });

                        // if (adv01Matid) {
                        //     api.addTexture('https://labs.sketchfab.com/experiments/video-textures/videos/facetune.webm', {
                        //         loop: true,
                        //         mute: true
                        //     }, function(err, textureUid) { 
                        //         if (!err) {
                        //             advTexturId = textureUid;
                        //             adv01Mat.channels['DiffuseColor'].texture = {
                        //             uid: advTexturId
                        //             }; //Emission
                        //             adv01Mat.channels['EmitColor'].enable = true;
                        //             adv01Mat.channels['EmitColor'].factor = 1;
                        //             adv01Mat.channels['EmitColor'].texture = {
                        //             uid: advTexturId
                        //             };
                        //             api.setMaterial(adv01Mat); 
                        //             window.console.log('Added video texture with UID', textureUid);
                        //         }
                        //     });
                        // }

                        api.setCameraConstraints(cameraConsJsonFalse, function(err) {
                            api.setEnableCameraConstraints(true, { preventCameraConstraintsFocus: false }, function (err) {
                                if (!err) {
                                    window.console.log('Camera pan constraints disable');
                                }
                            });
                        });                     
                                        
                        //disable waiting gif while iframe is loading
                        //$("#hidemiddle-container").addClass("hidemiddle-container-anim");
                        $("#hidemiddle-container").remove();
                    });

                    

                    window.requestAllAppsOff = function requestAllAppsOff() {
                        if (!isProjOverMatLimit) {
                            Object.keys(myMaterials).forEach(function (mKey) {
                                var mValue = myMaterials[mKey];
                                mValue.channels.Opacity.enable = true;
                                mValue.channels.Opacity.factor = 0;
                                mValue.channels.EmitColor.factor = 0;
                                mValue.channels.EmitColor.enable = false;
                                mValue.channels.EmitColor.color = emptyEmitColor;
                                mValue.channels.AlbedoPBR.color = emptyAlbedoColor;
                                api.setMaterial(mValue);
                            }); 
                        }
                        else {
                            Object.keys(myDiraNodes).forEach(function (mDiraKey) {
                                var nodeValue = myDiraNodes[mDiraKey];
                                api.assignMaterial(nodeValue, emptyMatId, function(err) {
                                    // if (!err) {
                                    //     window.console.log('Material assigned '+ mDiraKey + ' ' + emptyMatId);
                                    // }
                                    // else
                                    //     window.console.log(err + ' ' +emptyMatId);
                                });
                            });
                            api.refreshDraw();
                        }                   
                    };

                    window.colorSelectedApp = function colorSelectedApp(appId,isSelected) {                                                        
                        if (!isProjOverMatLimit) {
                            if (isSelected) {        
                                selectedApp = myMaterials[appId];                
                                selectedApp.channels.Opacity.enable = true;
                                selectedApp.channels.Opacity.factor = 1;
                                selectedApp.channels.EmitColor.factor = 8;
                                selectedApp.channels.EmitColor.enable = true;
                                switch (appsStatusInitObj[appId]) {
                                    case "משוריין":                                
                                        selectedApp.channels.EmitColor.color = yellowColor;
                                        selectedApp.channels.AlbedoPBR.color = yellowColor;
                                        break;
                                    case "למכירה":
                                        selectedApp.channels.EmitColor.color = greenColor;
                                        selectedApp.channels.AlbedoPBR.color = greenColor;
                                        break;
                                    case "נמכר":
                                        selectedApp.channels.EmitColor.color = redColor;
                                        selectedApp.channels.AlbedoPBR.color = redColor;
                                        break;
                                    case "Promotion":
                                        selectedApp.channels.EmitColor.color = blueColor;
                                        selectedApp.channels.AlbedoPBR.color = blueColor;
                                        break;
                                    case "Presale":
                                        selectedApp.channels.EmitColor.color = orangeColor;
                                        selectedApp.channels.AlbedoPBR.color = orangeColor;
                                        break;
                                    default:
                                        selectedApp.channels.EmitColor.color = emptyEmitColor;
                                        selectedApp.channels.AlbedoPBR.color = emptyAlbedoColor;
                                }
                            } else if (selectedApp) { 
                                if (!appsStatusObj[appId]) {
                                    selectedApp.channels.EmitColor.color = emptyEmitColor;
                                    selectedApp.channels.AlbedoPBR.color = emptyAlbedoColor; 
                                }                         
                                selectedApp.channels.Opacity.enable = true;
                                selectedApp.channels.Opacity.factor = 0.2;
                                selectedApp.channels.EmitColor.factor = 2;
                                selectedApp.channels.EmitColor.enable = true;
                            }
                            api.setMaterial(selectedApp);                    
                        } else {
                            if (isSelected) {
                                selectedNode = myDiraNodes[appId];                              
                                api.assignMaterial(selectedNode, selectedMatId, function(err) {
                                    // if (!err) {
                                    //     window.console.log('Material assigned '+ appId + ' ' + selectedMatId);
                                    // }
                                    // else
                                    //     window.console.log(err + ' ' + selectedMatId);
                                });
                            } else if (selectedNode) {
                                switch (appsStatusObj[appId]) {
                                    case ("למכירה"):
                                    case ("Promotion"):
                                    case ("Presale"):
                                        api.assignMaterial(selectedNode, greenMatId, function(err) {
                                            // if (!err) {
                                            //     window.console.log('Material assigned '+ appId + ' ' + greenMatId);
                                            // }
                                            // else
                                            //     window.console.log(err + ' ' + greenMatId);
                                        });
                                        break;                                                      
                                    default:
                                        api.assignMaterial(selectedNode, emptyMatId, function(err) {
                                            // if (!err) {
                                            //     window.console.log('Material assigned '+ appId + ' ' + emptyMatId);
                                            // }
                                            // else
                                            //     window.console.log(err + ' ' + emptyMatId);
                                        });                                    
                                }
                            }
                            api.refreshDraw();
                        }                    
                    };


                    api.addEventListener('camerastart', function() {
                        window.console.log('Camera is moving');
                        //cameraMov = true;
                        cameraMov = window.setInterval(checkCamConstraint, 500);
                        //$("#project-3d").on('mousemove', checkCamConstraint);
                    });

                    api.addEventListener('camerastop', function() {
                        window.console.log('Camera stopped');
                        //cameraMov = false;
                        window.clearInterval(cameraMov);
                        // api.setCameraConstraints(cameraConsJsonFalse, function(err) {
                        //     api.setEnableCameraConstraints(true, { preventCameraConstraintsFocus: true }, function (err) {
                        //         if (!err) {
                        //             window.console.log('Camera pan constraints disable');
                        //         }
                        //     });
                        // });                  
                    });

                    // api.setCameraLookAtEndAnimationCallback(function(err) {
                    //     window.console.log('Camera stopped');
                    //     //cameraMov = false;
                    //     window.clearInterval(cameraMov);
                    //     // api.setCameraConstraints(cameraConsJsonFalse, function(err) {
                    //     //     api.setEnableCameraConstraints(true, { preventCameraConstraintsFocus: true }, function (err) {
                    //     //         if (!err) {
                    //     //             window.console.log('Camera pan constraints disable');
                    //     //         }
                    //     //     });
                    //     // }); 
                    // });

                    
                    api.addEventListener(
                        'click',
                        function (info) {
                            var text = 'Clicked on ';
                            if (info && info.material && info.material.name == "empty" ) {
                                text += info.material.name;
                                HandleModelResponseFunction(myEmptyNodes[info.instanceID])
                                api.setCameraLookAt(
                                    calcAppViewCamPoint(
                                        [origCameraTarget[0],origCameraTarget[1],(info.position3D[2])], 
                                        [info.position3D[0],info.position3D[1],(info.position3D[2]+200)],
                                        minDistanceToBuilding),
                                    info.position3D,
                                    2, function(err) {
                                        //light selected app
                                        window.console.log('light selected app');
                                        colorSelectedApp(myEmptyNodes[info.instanceID],false);
                                        colorSelectedApp(myEmptyNodes[info.instanceID],true);
                                    });                                                  
                            } else {
                                text += 'background';                            
                            }                        
                            console.log(text + info.instanceID);
                        },
                        {
                            pick: 'slow'
                        }
                    );

                    api.addEventListener(
                        'nodeMouseLeave',
                        function(info) {
                            if (isProjOverMatLimit) {
                                if (info && info.material && info.material.name == "empty" ) {
                                    if (highLightNode != selectedNode) {
                                        switch (appsStatusObj[myEmptyNodes[info.instanceID]]) {
                                            case ("למכירה"):
                                            case ("Promotion"):
                                            case ("Presale"):
                                                //api.assignMaterial(highLightNode, greenMatId, function(err) {
                                                api.assignMaterial(highLightNode, prevMatId, function(err) {
                                                    // if (!err) {
                                                    //     window.console.log('Material assigned '+ appId + ' ' + greenMatId);
                                                    // }
                                                    // else
                                                    //     window.console.log(err + ' ' + greenMatId);
                                                });
                                                break;                                                      
                                            default:
                                                api.assignMaterial(highLightNode, emptyMatId, function(err) {
                                                    // if (!err) {
                                                    //     window.console.log('Material assigned '+ appId + ' ' + emptyMatId);
                                                    // }
                                                    // else
                                                    //     window.console.log(err + ' ' + emptyMatId);
                                                });                                    
                                        }
                                    }
                                }
                            }
                        },
                        {
                            pick: 'slow'
                        }
                    );

                    api.addEventListener(
                        'nodeMouseEnter',
                        function(info) {
                            if (info && info.material && info.material.name == "empty" ) {
                                var matToLite = highLightMat;
                                if (isProjOverMatLimit) {
                                    // if (highLightNode) {
                                    //     switch (appsStatusObj[myEmptyNodes[info.instanceID]]) {
                                    //         case ("למכירה"):
                                    //         case ("Promotion"):
                                    //         case ("Presale"):
                                    //             api.assignMaterial(highLightNode, greenMatId, function(err) {
                                    //                 // if (!err) {
                                    //                 //     window.console.log('Material assigned '+ appId + ' ' + greenMatId);
                                    //                 // }
                                    //                 // else
                                    //                 //     window.console.log(err + ' ' + greenMatId);
                                    //             });
                                    //             break;                                                      
                                    //         default:
                                    //             api.assignMaterial(highLightNode, emptyMatId, function(err) {
                                    //                 // if (!err) {
                                    //                 //     window.console.log('Material assigned '+ appId + ' ' + emptyMatId);
                                    //                 // }
                                    //                 // else
                                    //                 //     window.console.log(err + ' ' + emptyMatId);
                                    //             });                                    
                                    //     }
                                    // }
                                    if (highLightNode != myDiraNodes[myEmptyNodes[info.instanceID]]) {
                                        highLightNode = myDiraNodes[myEmptyNodes[info.instanceID]];
                                        prevMatId = highLightNode.materialID;
                                        if (highLightNode == selectedNode) {
                                            api.assignMaterial(highLightNode, selectedMatId, function(err) {
                                                // if (!err) {
                                                //     window.console.log('Material assigned '+ myEmptyNodes[info.instanceID] + ' ' + highliteMatId);
                                                // }
                                                // else
                                                //     window.console.log(err + ' ' + highLightMat);
                                            });
                                            matToLite = selectedMat;
                                        } else {
                                            api.assignMaterial(highLightNode, highLightMat.id, function(err) {
                                                // if (!err) {
                                                //     window.console.log('Material assigned '+ myEmptyNodes[info.instanceID] + ' ' + highliteMatId);
                                                // }
                                                // else
                                                //     window.console.log(err + ' ' + highLightMat);
                                            });                                        
                                        }
                                    }
                                } else {
                                    matToLite = myMaterials[myEmptyNodes[info.instanceID]];
                                }
                                api.highlightMaterial(matToLite);                            
                            }
                        },
                        {
                            pick: 'slow'
                        }
                    );
                    

                    $(".screenshot3d").click(function (event) {   
                        // api.addVideoTexture(videoTextureUrl, {
                        //             mute: true,
                        //             loop: true
                        //         }, function (err, textureId) {                                
                        //             advTexturId = textureId;
                        //             // adv01Mat.channels.AlbedoPBR.enable = true;
                        //             // adv01Mat.channels.AlbedoPBR.texture = {
                        //             // uid: advTexturId
                        //             // }; //Emission
                        //             //adv01Mat.channels.AlbedoPBR.color = [1, 1, 1, 0];
                        //             adv01Mat.channels.Opacity.factor = 0.95;
                        //             adv01Mat.channels['DiffuseColor'].texture = {
                        //             uid: advTexturId
                        //             }; //Emission
                        //             adv01Mat.channels['EmitColor'].enable = true;
                        //             adv01Mat.channels['EmitColor'].factor = 1;
                        //             adv01Mat.channels['EmitColor'].texture = {
                        //             uid: advTexturId
                        //             };
                        //             api.setMaterial(adv01Mat); 
                        //             window.console.log('Added video texture with UID', textureId);                                
                        //         });
                        
                        console.log(navigator.deviceMemory);
                        api.getScreenShot('image/png', function (err, result) {
                            var anchor = document.createElement('a');
                            anchor.href = result;
                            anchor.download = 'screenshot.png';
                            anchor.style.display = 'none';
                            document.body.appendChild(anchor);
                            anchor.click();
                            document.body.removeChild(anchor);
                        });
                    });

                    $(".resetcam").click(function (event) {
                        //api.setEnableCameraConstraints(false, { preventCameraConstraintsFocus: false })
                        api.setCameraLookAt(origCameraPos, origCameraTarget, 3); // [x, y, z]
                        //api.unselectAnnotation(0);
                        // api.hideAnnotationTooltips(function(err) {
                        //     if (!err) {
                        //         window.console.log('Hiding annotation tooltip');
                        //     }
                        // });                   

                    });

                    $(".toggle-lightAppsOn").click(function (event) {
                        //$('.temp_information').data('temp')
                        //let apartments = JSON.parse($("#project-apartments")[0].dataset.apartmentsfilter);
                        
                        requestFilterAppsByStatus(lastFilterAppStatusString,true);                  
                        $(".toggle-lightAppsOn").css("display", "none")
                        $(".toggle-lightAppsOff").css("display", "flex")                 
                    });

                    $(".toggle-lightAppsOff").click(function (event) {
                        requestAllAppsOff();
                        $(".toggle-lightAppsOff").css("display", "none")
                        $(".toggle-lightAppsOn").css("display", "flex")                 
                    }); 
                    
                    $(".toggle-3dbuildOn").click(function (event) {
                        playEnvAnimation();
                        $(".toggle-3dbuildOn").css("display", "none")
                        $(".toggle-3dbuildOff").css("display", "flex")                 
                    });

                    $(".toggle-3dbuildOff").click(function (event) {
                        playEnvAnimation();
                        $(".toggle-3dbuildOff").css("display", "none")
                        $(".toggle-3dbuildOn").css("display", "flex")                 
                    });


                    // $(".toggle-map").click(function (event) {
                    //     //setEnvAnimation();
                    //     playEnvAnimation();
                    // });


                    function playEnvAnimation() {
                        if (isShow3DMap) {
                                // api.getAnnotation(0, function(err, information) {
                                //     if (!err) {
                                //         window.console.log(information);
                                //     }
                                // });
                                // api.setSpeed(1, function(err) {
                                //     api.play();
                                //     isShow3DMap = false;
                                // });
                                //api.hideAnnotation(0);                        
                                // api.setAnnotationsTexture(
                                //     {url:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAADElEQVQImWNgoBMAAABpAAFEI8ARAAAAAElFTkSuQmCC",
                                //     padding: 2,
                                //     iconSize: 48,
                                //     colNumber: 10});
                                if (envAnimationUID != undefined){
                                    api.setSpeed(1, function(err) {
                                        isShowBuildings = false;
                                        window.console.log(isShowBuildings);
                                        //api.seekTo(0.01);
                                        api.play();                                
                                    });
                                }
                                else {
                                    api.hide(scan3DId);
                                    api.hide(scan2DId);
                                    setBackground(false);
                                    api.show(map2DId);
                                    isShow3DMap = false;
                                }
                        } else {
                            // api.setSpeed(-1, function(err) {
                            //     api.play();
                            //     isShow3DMap = true;
                            // });
                            //api.showAnnotation(0);
                            api.hide(map2DId);                        
                            api.show(scan3DId);
                            api.show(scan2DId);
                            isShow3DMap = true;                             

                            if (envAnimationUID != undefined){
                                api.setSpeed(-1, function(err) {
                                    isShowBuildings = true;
                                    window.console.log(isShowBuildings);
                                    //api.seekTo(0.99);
                                    api.play();                                
                                });
                            }  
                            setBackground(true);                  
                        } 
                    } 


                    // api.addEventListener('animationPlay', function() {
                    //     window.console.log('Animation is now playing');
                    //     api.getCurrentTime(function(err, time) {
                    //         if (!err) {
                    //             window.console.log('Current animation time:', time);
                    //         }
                    //     });
                    // });

                    api.addEventListener('animationEnded', function() {
                        if (isEnvAnimationCurr) {
                            window.console.log('Animation ended');
                            if (isShowBuildings){
                                api.seekTo(0.01);                               
                            }
                            else{
                                api.seekTo(0.99);
                                api.hide(scan3DId);
                                api.hide(scan2DId);
                                setBackground(false);
                                api.show(map2DId);
                                isShow3DMap = false;     
                            } 
                            //setPoiAnimation();                       
                        }                  
                    });

                    // api.addEventListener('animationStop', function() {
                    //     window.console.log('Animation stopped');
                    //     api.getCurrentTime(function(err, time) {
                    //         if (!err) {
                    //             window.console.log('Current animation time:', time);
                    //         }
                    //     });
                    // });

                    $(".toggle-surroundings").click(function (event) {

                        if ($("#project-3d-tour").length > 0){ 
                            $("#project-3d-tour").show();
                        }
                        else{                    
                            api.setEnableCameraConstraints(false, { preventCameraConstraintsFocus: false })                    
                            api.setCameraLookAt([origCameraTarget[0],(origCameraTarget[1]-5000),maxDistanceToSurround],
                                origCameraTarget, 4); // [x, y, z]
                        }
                        
                        
                            //api.setCameraLookAt([0,-2000,40000],[0,0,0], 4);
                        // api.showAnnotationTooltips(function(err) {
                        //     if (!err) {
                        //         window.console.log('Showing annotation tooltip');
                        //         api.gotoAnnotation(0,  {preventCameraAnimation: true, preventCameraMove: false}, function(err, index) {
                        //             if (!err) {
                        //                 window.console.log('Going to annotation', index + 1);
                        //            }
                        //         });
                        //     }
                        // });             
                    });


                    $(".screenshot3dtemp").click(function (event) {
                    //     api.getAnimations(function(err, animations) {
                    //         if (!err) {
                    //             if ((animations != undefined ) && (animations.length > 0))
                    //             {
                    //                 envAnimationUID = animations[0][0];
                    //                 api.setCurrentAnimationByUID(envAnimationUID, function(err) {
                    //                     if (!err) {
                    //                         window.console.log('Set animation track to', envAnimationUID);
                    //                     }
                    //                 });

                    //                 api.setCycleMode('one', function(err) {
                    //                     if (!err) {
                    //                         window.console.log('Set animation cycle mode');
                    //                     }
                    //                 });                                
                    //             }
                    //         }
                    //     });

                        
                        
                        api.getMaterialList(function (err, materials) {
                            
                            if (!err) {
                                window.console.log(materials); // [ ... ]
                                window.console.log(myMaterials);
                            }        
                            
                            api.getNodeMap(function (err, nodes) {
                                
                                if (!err) {
                                    window.console.log(nodes); // [ ... ]
                                    window.console.log(myEmptyNodes);
                                    window.console.log(myDiraNodes);
                                }
                                api.getSceneGraph(function (err, graph) {
                                    mygraph = graph;
                                    if (!err) {
                                        window.console.log(graph); // { ... }
                                    }
                                });
                            });
                        });  

                        // Object.keys(myDiraNodes).forEach(function (ap_id) {
                        //     api.assignMaterial(myDiraNodes[ap_id], emptyMatId, function(err) {
                        //         if (!err) {
                        //             window.console.log('Material assigned '+ ap_id + ' ' + emptyMatId);
                        //         }
                        //         else
                        //             window.console.log(err + ' ' +emptyMatId);
                        //     });
                        // });

                        // api.refreshDraw();
                        
                        // api.assignMaterial(myDiraNodes["A_3"], emptyMatId, function(err) {
                        //     if (!err) {
                        //         window.console.log('Material assigned '+ emptyMatId);
                        //     }
                        //     else
                        //         window.console.log(err + ' ' +emptyMatId);
                        // });
                        
                    });


                    /*function setPoiAnimation() {
                        api.pause();
                        api.setCurrentAnimationByUID(poiAnimationUID, function(err) {
                            if (!err) {
                                window.console.log('Set animation track to', poiAnimationUID);
                                api.setCycleMode('loopOne', function(err) {
                                    if (!err) {
                                        window.console.log('Set animation cycle mode loopOne');
                                        isEnvAnimationCurr = false;
                                        api.setSpeed(1, function(err) {                        
                                            api.play();        
                                        });
                                    }
                                });
                            }
                        });
                    }*/

                    function setBackground(isShown) {
                        //if (panoMatId != 0) {
                            if (isShown) {
                                //panoMat.channels.EmitColor.enable = true;
                                //panoMat.channels.DiffusePBR.factor = 0.5;
                                //panoMat.channels.AlbedoPBR.factor = 0.5;
                                api.setBackground({color: [0, 0, 0]}, function() {
                                    window.console.log('Background changed white');
                                    api.show(background3DId);
                                });

                            } else {
                                //panoMat.channels.EmitColor.enable = false;
                                //panoMat.channels.DiffusePBR.factor = 0;
                                //panoMat.channels.AlbedoPBR.factor = 0;
                                api.setBackground({color: [0, 0, 0]}, function() {
                                    window.console.log('Background changed black');
                                    api.hide(background3DId);
                                });                                
                            }
                            //api.setMaterial(panoMat);
                        //}
                    }

                    function setEnvAnimation() {
                        api.pause();
                        api.setCurrentAnimationByUID(envAnimationUID, function(err) {
                            if (!err) {
                                window.console.log('Set animation track to', envAnimationUID);
                                api.setCycleMode('one', function(err) {
                                    if (!err) {
                                        window.console.log('Set animation cycle mode one');
                                        isEnvAnimationCurr = true;
                                        //playEnvAnimation();
                                    }
                                });
                            }
                        }); 

                        return isEnvAnimationCurr;
                    }



                    function checkCamConstraint() {
                        //while (cameraMov) {
                            //console.log(e.clientX);
                            api.getCameraLookAt(function (err, camera) {                        
                                var dist = getLength(addVectors(camera.position, scalarMultiply(origCameraTarget, -1))); 
                                if ((dist < minDistanceToActivateCons) || (dist > maxDistanceToActivateCons) || (camera.position[2] < minHightToActivateCons)){ 
                                    console.log(camera.position)
                                    api.setCameraConstraints(cameraConsJsonTrue, function(err) {
                                        api.setEnableCameraConstraints(true, { preventCameraConstraintsFocus: false }, function (err) {
                                            if (!err) {
                                                window.console.log('Camera pan constraints enabled');
                                            }
                                        });
                                    }); 
                                    api.setCameraConstraints(cameraConsJsonFalse, function(err) {
                                        api.setEnableCameraConstraints(true, { preventCameraConstraintsFocus: false }, function (err) {
                                            if (!err) {
                                                window.console.log('Camera pan constraints disabled');
                                            }
                                        });
                                    });  
                                }
                                // if (firstAnimationUID != undefined)
                                // {
                                //     if ((dist < 20000) && (isShowBuildings)){
                                //         api.setSpeed(1, function(err) {
                                //             api.play();
                                //             isShowBuildings = false;
                                //         });
                                //         //api.hide(simplexMapId);
                                //         //api.hide(osmMapId);
                                //         //isShow3DMap = false;
                                //     } else if ((dist > 20000) && (!isShowBuildings)){
                                //         api.setSpeed(-1, function(err) {
                                //             api.play();
                                //             isShowBuildings = true;
                                //         });
                                //         // api.show(simplexMapId);
                                //         // api.show(osmMapId);
                                //         //isShow3DMap = true; 
                                //     }
                                // }
                            }); 
                        //}      
                    }

                },

                error: function onError() {
                    console.log('Viewer error');
                }
            });
        }
    } 
    
    function addVectors(v1, v2) {
        return v1.map((x, i) => x + v2[i]);
    }
    
    function scalarMultiply(v, c) {
        return v.map(x => x*c);
    }
    
    function getLength(v) {
        return Math.hypot(...v);
    }
    
    //A = origTarget, B = origCameraPosition
    function calcCloseViewCamPoint(A,B,dist) {
        var vecA2B = addVectors(B, scalarMultiply(A, -1)); // Step 1
        var normA2B = scalarMultiply(vecA2B, 1/getLength(vecA2B)); // Step 2
        var distA2B = scalarMultiply(normA2B, dist); // Step 3
        var C = addVectors(A, distA2B); // Final step
        return C;
    }

    //A = origTarget, B = touchPoint (app selected)
    function calcAppViewCamPoint(A,B,dist) {
        var vecA2B = addVectors(B, scalarMultiply(A, -1)); // Step 1
        var normA2B = scalarMultiply(vecA2B, 1/getLength(vecA2B)); // Step 2
        var distA2B = scalarMultiply(normA2B, dist); // Step 3
        var C = addVectors(B, distA2B); // Final step
        return C;
    }

    
    $("#apartment-details-link").click(function () {
        if ($("#contract-3d-model").is(":visible"))
            $("#enlarge-3d-model").click();
    });

    $("#project-3d-tour-close").click(function () {
        if ($("#project-3d-tour").length > 0)
            $("#project-3d-tour").hide();
    });

    function HandleModelResponseFunction(data) {
        let apartments = JSON.parse($("#project-apartments")[0].dataset.apartments);
        //var apt_number = data.toString().split('_')[1]
        var apt_number = data.toString();
        if (apartments[apt_number]) {

            var apt = JSON.parse(apartments[apt_number])

            if (apt["status"] != "נמכר") {

                $("#apartment-details-box").addClass('active').show();
                $("#apartment-details-box_empty").removeClass('active').hide();            
                $("#apartment-number").html(apt["number"]);
                $("#apartment-status").html(apt["status"]);
                $("#apartment-status").attr('sale-color', apt["status"]);
                $("#apartment-price").html(apt["price"]);
                $("#apartment-rooms-number").html(apt["rooms_number"]);
                $("#apartment-floor").html(apt["floor"]);
                $("#apartment-area-size").html(apt["area_size"]);
                $("#apartment-porch-area").html(apt["porch_area"]);
                $("#apartment-parking-number").html(apt["parking_number"]);
                if (apt["program_option_1_version_1"] != null) {                
                    //$("#apartment-program-image").html('<a class="btn btn-submit" data-remote="true" href="/projects/' + apt["project_id"] + '/apartments/' + apt["id"] + '?div_class=.show-ap-full.detailed-content" /> <img src="' + apt["program_option_1_version_1"][0]["service_url"] + '" class="apartment-details-img" />');
                    $("#apartment-program-image").html('<a class="btn btn-submit" data-remote="true" href="/projects/' + apt["project_id"] + '/apartments/' + apt["id"] + '?div_class=.show-ap-tab.detailed-content" /> <img src="' + apt["program_option_1_version_1"][0]["url"] + '" class="apartment-details-img" />');
                    $("#apartment-program-image").show();
                    //$("#apartment-details-img").attr("href", "/projects/" + apt["project_id"] + "/apartments/" + apt["id"] + "?div_class=.show-apartment.detailed-content");
                }
                else{
                    $("#apartment-program-image").hide();
                }
                if (apt["is_3d_tour_exist"] == "true") {                
                    $("#apartment-3d-image").html('<i class="bi bi-badge-3d fs-2 text-success"></i>');
                    $("#apartment-3d-image").show();                
                }
                else{
                    $("#apartment-3d-image").hide();
                }

                //$("#apartment-details-link").attr("href", "/projects/" + apt["project_id"] + "/apartments/" + apt["id"] + "?div_class=.show-ap-full.detailed-content");
                $("#apartment-details-link").attr("href", "/projects/" + apt["project_id"] + "/apartments/" + apt["id"] + "?div_class=.show-ap-tab.detailed-content");

                var closeBTN = $("#apartment-details-box .btn-close");
                closeBTN.off("click");
                closeBTN.one("click", () =>{
                    $("#apartment-details-box").removeClass('active').hide();
                });
            }
            else{
                $("#apartment-details-box_empty").addClass('active').show();
                $("#apartment-details-box").removeClass('active').hide();
    
                var closeBTN = $("#apartment-details-box_empty .btn-close");
                closeBTN.off("click");
                closeBTN.one("click", () =>{
                    $("#apartment-details-box_empty").removeClass('active').hide();
                });
            }  
        }
        else{
            $("#apartment-details-box_empty").addClass('active').show();
            $("#apartment-details-box").removeClass('active').hide();

            var closeBTN = $("#apartment-details-box_empty .btn-close");
            closeBTN.off("click");
            closeBTN.one("click", () =>{
                $("#apartment-details-box_empty").removeClass('active').hide();
            });
        }        
    }
});