$(document).on('turbolinks:load', function() {


    // new code
    $("#design-content button.btn-black").on("click", function(event) {

        const slideshow = document.getElementById("full-slideshow");
                
        if(event.target.classList.contains("interior")){            
            slideshow.querySelector("#indoor-carousel").removeAttribute("hidden");
        } else if(event.target.classList.contains("exterior")){
            slideshow.querySelector("#outdoor-carousel").removeAttribute("hidden");
        } else return;

        if ((document.fullscreenEnabled != null) && (document.fullscreenEnabled)) {
            //slideshow.webkitRequestFullscreen();
            if (slideshow.requestFullscreen) {
				slideshow.requestFullscreen();
			} else if (slideshow.mozRequestFullScreen) {
				slideshow.mozRequestFullScreen();
			} else if (slideshow.webkitRequestFullScreen) {
                slideshow.webkitRequestFullScreen();
			} else if (slideshow.msRequestFullscreen) {
				slideshow.msRequestFullscreen();
			}
        }
        else {
            slideshow.style.position = "absolute";
            $("#design-content").prop("hidden",true);
            $(".project_nav_control").prop("hidden",true);
        }
        
        slideshow.removeAttribute("hidden");
        document.querySelector(".custom-menu").setAttribute("hidden", "");
    });

    // $('#design-tab').click(function () {
    //     design();
    // });


    // $(window).resize(function(){
    //     design();
    //     specifications();
    //     locationMap();
    // });
    // function design(){
    //      var classFound = $('#design-tab').parents('.nav-item').find('.active');
    //         var theHeight = $('#design').parents('.main-content-area').outerHeight() - 2;
    //         if (classFound) {
    //             $('.the-architect').css('height', theHeight + 'px');
    //      }
    //  }
    // function specifications(){
    //     var classFound = $('#specifications-tab').parents('.nav-item').find('.active');
    //            var theHeight = $('#specifications').parents('.main-content-area').outerHeight()-2;
    //            if (classFound) {
    //                $('.specifications-image').css('height', theHeight + 'px');
    //            }
    //         }

    //     function locationMap() {
    //      var classFound = $('#location-tab').parents('.nav-item').find('.active');
    //     if (classFound) {
    //         $('#mapLocation').css('height', theHeight + 'px');
    //     }
    // }

});
