// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require.context("../images", true);

// bootstrap individual plugins
// require("bootstrap/js/src/tab"); // for data-bs-toggle to work in list component
// require("bootstrap/js/src/tooltip"); // for data-bs-toggle to work in list component
// require("bootstrap/js/src/collapse"); // for accordion to work
// require("bootstrap/js/src/carousel"); // for accordion to work

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("jquery-validation");
require("channels");
require("async");
require("print-this");
require("nouislider");
//require("jquery-validation");
//import 'jquery-validation';
//require("javascripts/apartments");
require("javascripts/gmaps");
// require("javascripts/project");
require("javascripts/functions");
require("javascripts/compare_apartments");
require("javascripts/design");
require("javascripts/meeting_sum");
require("javascripts/index");
require("javascripts/preorder");
require("javascripts/map");
require("javascripts/cesium_map");
require("javascripts/components/menu"); // hamburger menu
require("javascripts/validation");
// //require('javascripts/model')
require("javascripts/proj_model");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
