import async from 'async'
import {
  createPopper
} from '@popperjs/core';

window.close_popup_legal = function close_popup_legal() {
  $("#popup-legal").hide(); 
};

window.change_show_legal = function change_show_legal() {
  //$("#apartments_status_show_price").on("change", function (ev) {
    //if ($(this).parents("#apartments-search-form").length > 0) {
      var jsonPayLoad = {is_show_legal:$("#is_show_legal")[0].checked}
      $.ajax({
        url: '/set_show_legal',
        data: JSON.stringify(jsonPayLoad),
        dataType: "json",
        type: "POST",
        contentType: "application/json; charset=utf-8",
      }).done(function(data) {        
        //Rails.fire($("#apartments-search-form")[0], "submit");     
      })       
    //}
  //});
};

function replaceUrlParam(url, paramName, paramValue) {
  if (paramValue == null) {
    paramValue = '';
  }
  var pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, '$1' + paramValue + '$2');
  }
  url = url.replace(/[?#]$/, '');
  return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
}

function delay(callback, ms) {
  var timer = 0;
  return function() {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      callback.apply(context, args);
    }, ms || 0);
  };
}

function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;
  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
};
var autocompleteService, placesService, results, map;

function initializeAutocomplete(address_input_id) {
  results = document.getElementById('results');

  var address_input = $("#" + address_input_id);
  if (address_input.length > 0) {
    const sameWidth = {
      name: "sameWidth",
      enabled: true,
      phase: "beforeWrite",
      requires: ["computeStyles"],
      fn: ({
        state
      }) => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
      },
      effect: ({
        state
      }) => {
        state.elements.popper.style.width = `${
              state.elements.reference.offsetWidth
            }px`;
      }
    };
    const popperInstance = createPopper(address_input.get(0), results, {
      placement: 'bottom-start',
      modifiers: [{
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
        {
          name: 'flip',
          enabled: false,
        },
        sameWidth,
      ],
    });
    address_input.on('input', function() {

      if (address_input.val() === '') {
        results.classList.add("d-none");
      } else {
        results.classList.remove("d-none");
        popperInstance.update();
        getPlacePredictions(address_input.val());
        // setTimeout(function(){ 
        //   $("#results .list-group-item").first().addClass("selected");
        // }, 1000);
      }
    });

    // Show results when address field is focused (if not empty)
    address_input.on('focus', function() {
      if (address_input.val() !== '') {
        // $("#results").addClass("active");
        results.classList.remove("d-none");
        getPlacePredictions(address_input.val());
      } else if (address_input.data('search-history') != undefined) {
        results.classList.remove("d-none");
        var search_history = JSON.parse(address_input.attr("data-search-history").replace(/&quot;/g, '"')).slice(0, 5);
        show_search_history_results(search_history)
        // setTimeout(function(){ 
        //   $("#results .list-group-item").first().addClass("selected");
        // }, 1000);
      }
    });
    // Hide results when click occurs out of the results and inputs
    google.maps.event.addDomListener(document, 'click', function(e) {
      if (results != null && (e.target.parentElement != null) && (e.target.parentElement.className !== 'pac-container') && (e.target.parentElement.className !== 'list-group-item') && (e.target.tagName !== 'INPUT')) {
        results.classList.add("d-none");
      }
    });
    autocompleteService = new google.maps.places.AutocompleteService();
    placesService = new google.maps.places.PlacesService(document.createElement('div'));

    var input = $("#autocomplete_search_address");

    //$(document).on("keydown", function(e) {
    input.on("keydown", function(e) {
      // e.preventDefault();
      var current = $(".selected");
      
      if (e.which == 38) {
        // UP KEY
        var prev = current.prev(".custom_choose");
        if (prev.length) {
          current.removeClass("selected");
          prev.addClass("selected");
        }
      } else if (e.which == 40) {
        // DOWN KEY
        if (current.length == 0) {
	        $("#results .list-group-item").first().addClass("selected");
        }
        else {
          var next = current.next(".custom_choose");
          if (next.length) {
            current.removeClass("selected");
            next.addClass("selected");
          }
        }
      } else if (e.which == 13) {
        // ENTER KEY
        var code = $(".selected").text();
        input.val(code + " ").focus();
        $('.custom_choose.selected').trigger('click');
      }
    });


  }
}
// Get place predictions
function getPlacePredictions(search) {
  async.parallel({
    places: function(callback) {
      autocompleteService.getPlacePredictions({
        input: search,
        types: ['establishment', 'geocode'],
        componentRestrictions: {
          country: 'il'
        }
      }, function(places, status) {
        if (status == google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          callback(null, [])
        } else if (status == google.maps.places.PlacesServiceStatus.OK) {
          callback(null, places)
        } else {
          callback("error")
        }
      });
    },
    projects: function(callback) {
      $.ajax({
          url: '/get_projects?query=' + search,
          dataType: "json",
          type: "GET"
        }).done(function(projects) {
          callback(null, projects);
        })
        .fail(function() {
          callback("error");
        });
    }
  }, function(error, results) {
    if (error != null) {
      console.log(error)
    } else {
      show_results(results.places, results.projects)
    }
  });
}
// Get place details
function getPlaceDetails(placeId) {
  var request = {
    placeId: placeId
  };
  placesService.getDetails(request, function(place, status) {
    results.classList.add("d-none");
    // $(".search-form .search-filter").val(place.formatted_address);
    onPlaceChanged(place)
  });
}
// Returns single search-result-item as HTML string
function generateSearchItem(dataIdType, dataProjectId, dataName, content) {
  return `<button type="button" class="custom_choose list-group-item list-group-item-action" data-${dataIdType}id="${dataProjectId}" data-name="${dataName}">${content}</button>`;
}
// Place search callback
function show_search_history_results(search_history = []) {
  // Empty results container
  results.innerHTML = '';
  // Place service status error
  search_history.forEach(result => {
    if (result.id != undefined) {
      results.innerHTML += generateSearchItem("project", result.id, result.name, result.name);
    } else if (result.place_id != undefined) {
      results.innerHTML += generateSearchItem("place", result.place_id, result.description, result.description);
    }
  })
  set_results_click_events()

  $(".custom_choose").mouseenter(function () {
    $(".selected").removeClass("selected");
    //if ($(this).hasClass("selected")) $(this).removeClass("selected");
    //else 
    $(this).addClass("selected");
  });

  $('.custom_choose').mouseout(function () {
    if ($(this).hasClass("selected")) $(this).removeClass("selected");
  });
}
// Place search callback
function show_results(places = [], projects = []) {
  // Empty results container
  results.innerHTML = '';
  // Place service status error
  if (places.length + projects.length == 0) {
    results.innerHTML = '<a class="list-group-item list-group-item-danger">אין תוצאות</a>';
    return;
  }
  for (var i = 0, project; project = projects[i]; i++) {
    results.innerHTML += generateSearchItem("project", project.id, project.name, project.name);
  }
  // Build output for each prediction
  for (var i = 0, place; place = places[i]; i++) {
    // Insert output in results container
    results.innerHTML += generateSearchItem("place", place.place_id, place.description, place.description);
  }
  set_results_click_events()

  $(".custom_choose").mouseenter(function () {
    $(".selected").removeClass("selected");
    //if ($(this).hasClass("selected")) $(this).removeClass("selected");
    //else 
    $(this).addClass("selected");
  });

  $('.custom_choose').mouseout(function () {
    if ($(this).hasClass("selected")) $(this).removeClass("selected");
  });
}

// Results items click
function set_results_click_events() {
  //Array.from(document.getElementsByClassName("list-group-item")).forEach(item => {
  Array.from($('#results .list-group-item')).forEach(item => {
    item.onclick = function() {
      var tmpItem = item;
      $.ajax({
        url: '/is_signed_id',
        dataType: "json",
        type: "GET"
      }).done(function(data) {
        if (data.returnvalue === false)
          $('[data-bs-target="#login-reelook"]')[0].click();
        else {
          if (tmpItem.dataset.placeid) {
              $(".search-form .search-filter").val(tmpItem.dataset.name);
            getPlaceDetails(tmpItem.dataset.placeid);
          } else if (tmpItem.dataset.projectid) {
              window.location.href = '/projects/' + tmpItem.dataset.projectid + '?project_name=' + tmpItem.dataset.name + '&search_source=autocomplete';
              // Turbolinks.visit('/projects/' + tmpItem.dataset.projectid + '?project_name=' + tmpItem.dataset.name + '&search_source=autocomplete');
          }
        }
      })
      .fail(function() {
        callback("error");
      });  
    };
  });
}

//   $.ajax({
//     url: '/is_signed_id',
//     dataType: "json",
//     type: "GET"
//   }).done(function(data) {
//     if (data.returnvalue === false)
//       $('[data-bs-target="#login-reelook"]')[0].click();
//     else {
//       Array.from(document.getElementsByClassName("list-group-item")).forEach(item => {
//         item.onclick = function() {
//           if (this.dataset.placeid) {
//             getPlaceDetails(this.dataset.placeid);
//           } else if (this.dataset.projectid) {
//             Turbolinks.visit('/projects/' + this.dataset.projectid + '?project_name=' + this.dataset.name + '&search_source=autocomplete');
//           }
//         };
//       });
//     }
//   })
//   .fail(function() {
//     callback("error");
//   });  
// }

function cesiumFlyToLocation(l,cesiumMap) {
  if (l) {
    var latlngs = l.split(',');
    // var southWest = new google.maps.LatLng(latlngs[0], latlngs[1]);
    // var northEast = new google.maps.LatLng(latlngs[2], latlngs[3]);
    // var bounds = new google.maps.LatLngBounds(southWest, northEast);
    cesiumMap.camera.flyTo({
      destination : Cesium.Rectangle.fromDegrees(latlngs[1], latlngs[0], latlngs[3],latlngs[2])
    });
  }
}

function setSafeBounds(l, map) {
  if (l) {
    var latlngs = l.split(',');
    var southWest = new google.maps.LatLng(latlngs[0], latlngs[1]);
    var northEast = new google.maps.LatLng(latlngs[2], latlngs[3]);
    var bounds = new google.maps.LatLngBounds(southWest, northEast);
    map.getMap().fitBounds(bounds, 0);
  }
}

function onPlaceChanged(place) {
  $(".tab-content").removeClass("body-overlay");
  var bounds = place.geometry.viewport;
  var location_bounds = bounds.getSouthWest().toUrlValue() + "," + bounds.getNorthEast().toUrlValue();
  if ($("#map").length > 0) {
    $('#place_id').val(place.place_id)
    $('#l').val(location_bounds)
    $('#l_center').val(place.geometry.location.lat() + "," + place.geometry.location.lng())
    if ($("#map").length > 0) {
      $(".search-form .search-filter").parent('.search-mob').removeClass('focus');
    }
    $(".tab-content").removeClass("body-overlay");
    if ($(".search-form .search-filter").val().length == 0) {
      $(".close-icon").css("display", "none");
    }
    $("ul.ui-autocomplete.ui-menu.ui-widget.ui-widget-content.ui-corner-all").removeClass('left15');
    $("ul.ui-autocomplete.ui-menu.ui-widget.ui-widget-content.ui-corner-all").css('width', $(".search-form .search-filter").outerWidth());
    setSafeBounds(location_bounds, window.map);
    cesiumFlyToLocation(location_bounds,window.cesiumMap);
  } else {
    var url = "/projects?search_l=" + location_bounds + "&autocomplete_search_address=" + place.formatted_address + "&place_id=" + place.place_id
    window.location.href = url;
    // Turbolinks.visit(url);
  }
}

export {
  replaceUrlParam,
  getUrlParameter,
  initializeAutocomplete,
  setSafeBounds,
  delay,
  cesiumFlyToLocation
}
