import Rails from "@rails/ujs";

const array_comparer = (otherArray) => (current) =>
  otherArray.filter((other) => other.id == current.id).length == 0;

// Pick your pin (hole or no hole)
const pinSVGHole =
  "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
//Reelook marker
//const pinSVGHole =
//"M11 13 11 12 9 12 9 10 11 10 11 9 9 9 9 7 11 7 11 6 8 6 8 13ZM13 13 16 13 16 6 13 6 13 7 15 7 15 9 13 9 13 10 15 10 15 12 13 12 13 13ZM12 2.016Q14.906 2.016 16.945 4.055T18.984 9Q18.984 10.453 18.257 12.328T16.499 15.844 14.46 18.914 12.749 21.187L11.999 21.984Q11.718 21.656 11.249 21.117T9.561 18.961 7.428 15.82 5.764 12.375 5.014 9Q5.014 6.094 7.053 4.055T11.998 2.016Z"


/**
 * Generates new Google Maps map
 * @param {string} selector a selector for the div to insert the map into
 * @param {object} center an object of {lat: number, lng: number}
 * @param {function} clickHandler a function that runs when clicking the marker
 */
class MapGenerator {
  constructor({ selector, center, clickHandler, styles }) {
    // should expose map to window, because index.js.erb uses it
    this.clickHandler = clickHandler;
    this.map = new GMaps({
      div: selector,
      center: center,
      gestureHandling: "greedy",
      zoomControl: true,
      keyboardShortcuts: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER,
      },
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        //style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      fullscreenControl: false,
      styles,
    });

    this.projects = [];
    this.oldMarkers = [];

    this.marker_uid="";

    this.addListener = this.map.addListener.bind(this);

    this.markerImage = {
      // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
      path: pinSVGHole,
      anchor: new google.maps.Point(12, 17),
      fillOpacity: 1,
      fillColor: "#00305B",      
      strokeWeight: 1,
      strokeColor: "white",
      scale: 2,
      labelOrigin: new google.maps.Point(12, 15),
    };
    
  }

  _beforeAdd(projects_element_dataset_id, removeOldMarkers) {
    const element = document.querySelector(projects_element_dataset_id);
    const new_projects = (window.projects = JSON.parse(
      element.dataset.projects
    ));

    this.marker_uid = element.dataset.markerUid;

    if (removeOldMarkers && this.oldMarkers) {
      const delete_markers = this.oldMarkers.filter(
        array_comparer(new_projects)
      );

      delete_markers.forEach((marker, i) => {
        marker.setMap(null);
      });

      if (new_projects.length == 0) {
        return !(this.oldMarkers = []);
      }

      this.projects = new_projects.filter(array_comparer(this.oldMarkers));
      this.oldMarkers = this.oldMarkers.filter(array_comparer(delete_markers));
    } else {
      this.projects = new_projects;
      this.oldMarkers = [];
    }
    return true;
  }

  addMarkers(projects_element_dataset_id, removeOldMarkers) {
    if (!this._beforeAdd(projects_element_dataset_id, removeOldMarkers)) return;
    this.projects.forEach((project) => {
      if (project.address_lat && project.address_lon) {
        if (project.source == "inside") {
          this.markerImage.fillColor = "#00305B";
          this.markerImage.scale = 2;
        } else {
          this.markerImage.fillColor = "#c90223";
          this.markerImage.scale = 1.5;
        }
        const marker = this._addMarker(project);
        this.oldMarkers.push(marker);

        if (this.clickHandler) {
          google.maps.event.addListener(marker, "click", () => {
            this.clickHandler(marker);
          });
        }

        google.maps.event.addListener(marker, "mouseover", () => {
          this.markerImage.fillColor = "#f28c33";
          marker.setIcon(this.markerImage);
        });

        google.maps.event.addListener(marker, "mouseout", () => {
          if (marker.active != true) {
            if (project.source == "inside") {
              this.markerImage.fillColor = "#00305B";
              this.markerImage.scale = 2;
            } else {
              this.markerImage.fillColor = "#c90223";
              this.markerImage.scale = 1.5;
            }
          }
          marker.setIcon(this.markerImage);
        });
      }
    });
  }

  addMarkersWithInfoWindow(projects_element_dataset_id, removeOldMarkers) {
    if (this._beforeAdd(projects_element_dataset_id, removeOldMarkers))
      this.buildInfoWindow();
  }

  fitZoom() {
    this.map.fitZoom();
  }

  buildInfoWindow() {
    let mouseOverInfoWindow;
    let infowindowCloseTimeout;

    const infowindow = new google.maps.InfoWindow({
      disableAutoPan: true,
      //disableAutoPan: false
    });

    google.maps.event.addListener(infowindow, "mouseenter", () => {
      if (infowindowCloseTimeout) {
        clearTimeout(infowindowCloseTimeout);
      }
      mouseOverInfoWindow = true;
    });

    google.maps.event.addListener(infowindow, "mouseleave", () => {
      mouseOverInfoWindow = false;
    });

    this.projects.forEach((project) => {
      if (project.address_lat && project.address_lon) {
        if (project.source == "inside") {
          this.markerImage.fillColor = "#00305B";
          this.markerImage.scale = 2;
        } else {
          this.markerImage.fillColor = "#c90223";
          this.markerImage.scale = 1.5;
        }
        
        const marker = this._addMarker(project);
        this.oldMarkers.push(marker);

        google.maps.event.addListener(marker, "mouseover", () => {
          if (infowindowCloseTimeout) {
            clearTimeout(infowindowCloseTimeout);
          }
          mouseOverInfoWindow = true;

          // $("#project-url").val(
          //   "https://www.reelook.co.il/projects/" + project.id
          // );
          // $(".facebook-share").attr(
          //   "onclick",
          //   "window.open('https://www.facebook.com/sharer/sharer.php?u=https://www.reelook.co.il/projects/" +
          //     project.id +
          //     "&quote=" +
          //     project.address +
          //     " https://www.reelook.co.il/projects/" +
          //     project.id +
          //     "'); return false;"
          // );
          // $(".twitter-share").attr(
          //   "onclick",
          //   "window.open('https://twitter.com/intent/tweet?text=" +
          //     project.address +
          //     " https://www.reelook.co.il/projects/" +
          //     project.id +
          //     "'); return false;"
          // );
          // $(".email-share").attr(
          //   "onclick",
          //   "window.open('mailto:?subject=" +
          //     project.address +
          //     "&body=" +
          //     project.address +
          //     " https://www.reelook.co.il/projects/" +
          //     project.id +
          //     "'); return false;"
          // );
          // $(".whatsapp-share").attr(
          //   "href",
          //   "https://api.whatsapp.com/send?text=" +
          //     project.address +
          //     " https://www.reelook.co.il/projects/" +
          //     project.id
          // );
          // $(".telegram-share").attr(
          //   "onclick",
          //   "window.open('https://t.me/share/url?url=https://www.reelook.co.il/projects/" +
          //     project.id +
          //     "&text=" +
          //     project.address +
          //     " https://www.reelook.co.il/projects/" +
          //     project.id +
          //     "'); return false;"
          // );

          this.markerImage.fillColor = "#f28c33";
          marker.setIcon(this.markerImage);

          if (marker.source == "inside") {
            var link_opening =
              '<a href="/projects/' +
              marker.projectId +
              '" data-turbolinks="false">';
              var share_link = window.location.origin + "/projects/" + marker.projectId + 
              (marker.marker_uid=="" ? "" : "?uid=" + marker.marker_uid);
          } else {
            var link_opening =
              '<a data-remote="true" href="/entrepreneur/' +
              marker.entrepreneur_id +
              "/outside_project/" +
              marker.projectId +
              '">';
          }

          if (marker.address_route == null )
            marker.address_route = "";

          if (marker.address_street_number == null )
            marker.address_street_number = "";

          if (marker.source == "inside") {  
            infowindow.setContent(
              link_opening +
                '<div class="tooltip-content">' +
                '<div class="siteNotice">' +
                "</div>" +
                `<div class="bodyContent" style="background-image:url(${marker.main_image_url})">` +
                `<div class="overlay">` +
                `<div class="d-grid">` +
                `<h5 class="card_title" style="grid-area: city">${marker.address_route} ${marker.address_street_number}</h5>` +
                `<p class="card_address" style="grid-area: address">${marker.address_locality}</p>` +
                `<p sale-color = ${marker.sale_status} class="sale-in-marker center-self rounded-pill" style="grid-area: status">${marker.sale_status}</p>` +
                `</div>` +
                `<div class="summary d-flex justify-content-between">` +
                `<p class="summary_text">${marker.marker_description}</p>` +
                `<div class="d-flex marker_align">` +
                `<span class="like-marker" href="" data-toggle="tooltip" title="הוסף למועדפים">
                        <i class="bi bi-heart"></i>
                      </span>` +
                `<span class="share-marker" data-bs-toggle="modal" data-bs-target="#share-project" data-bs-val="${share_link}"  data-bs-href="${share_link}"  title="שתף" >
                        <i class="bi bi-share"></i>
                      </span>` +
                `</div>` +
                `</div>` +
                "</div>" +
                "</div>" +
                "</div></a>"
            );
          } else {
            infowindow.setContent(
              link_opening +
                '<div class="tooltip-content">' +
                '<div class="siteNotice">' +
                "</div>" +
                `<div class="bodyContent" style="background-image:url(${marker.main_image_url})">` +
                `<div class="overlay">` +
                `<div class="d-grid">` +
                `<h5 class="card_title" style="grid-area: city">${marker.address_route} ${marker.address_street_number}</h5>` +
                `<p class="card_address" style="grid-area: address">${marker.address_locality}</p>` +
                `<p sale-color = ${marker.sale_status} class="sale-in-marker center-self rounded-pill" style="grid-area: status">${marker.sale_status}</p>` +
                `</div>` +
                `<div class="summary d-flex justify-content-between">` +
                `<p class="summary_text">${marker.marker_description}</p>` +                
                `</div>` +
                "</div>" +
                "</div>" +
                "</div></a>"
            );
          }

          infowindow.open(this.map, marker);

          const markerWindow = document.querySelector(".gm-style .gm-style-iw");

          if (markerWindow) {
            var infoWindowElement = markerWindow.parentNode;

            infoWindowElement.addEventListener("mouseleave", () => {
              infowindow.close(this.map, marker);
              mouseOverInfoWindow = false;
            });

            infoWindowElement.addEventListener("mouseenter", () => {
              if (infowindowCloseTimeout) {
                clearTimeout(infowindowCloseTimeout);
              }
              mouseOverInfoWindow = true;
            });
          }

          google.maps.event.addListener(infowindow, "closeclick", () => {
            if (project.source == "inside") {
              this.markerImage.fillColor = "#00305B";
              this.markerImage.scale = 2;
            } else {
              this.markerImage.fillColor = "#c90223";
              this.markerImage.scale = 1.5;
            }
            marker.setIcon(this.markerImage);
          });

          google.maps.event.addListener(infowindow, "domready", () => {
            $(".share-marker").on("click", (e) => {
              e.preventDefault();
            });
          });
        });

        google.maps.event.addListener(marker, "mouseout", () => {
          if (marker.active != true) {
            if (project.source == "inside") {
              this.markerImage.fillColor = "#00305B";
              this.markerImage.scale = 2;
            } else {
              this.markerImage.fillColor = "#c90223";
              this.markerImage.scale = 1.5;
            }
          }
          marker.setIcon(this.markerImage);
          mouseOverInfoWindow = false;
          infowindowCloseTimeout = setTimeout(() => {
            if (!mouseOverInfoWindow) {
              infowindow.close(this.map, marker);
            }
          }, 250);
        });


        if (this.clickHandler) { 
          google.maps.event.addListener(marker, "click", () => {
            if ("ontouchstart" in document.documentElement) {
              google.maps.event.trigger(marker, 'mouseover');   
            }
            else {
              this.clickHandler(marker);
            }            
          });
        }
  
      }
    });
    return this.oldMarkers;
  }

  refresh() {
    var bounds = this.map.getBounds();
    var location_bounds =
      bounds.getSouthWest().toUrlValue() +
      "," +
      bounds.getNorthEast().toUrlValue();

    $("#l").val(location_bounds);

    Rails.fire($("#search-form")[0], "submit");
  }

  getMap() {
    return this.map;
  }

  _addMarker(project) {
    return this.map.addMarker({
      address_locality: project.address_locality,
      address_route: project.address_route,
      address_street_number: project.address_street_number,
      main_image_url: project.marker_main_image_url,
      source: project.source,
      projectId: project.id,
      id: project.id,
      marker_description: project.marker_description,
      sale_status: project.sale_status,
      entrepreneur_id: project.entrepreneur.id,
      lat: project.address_lat,
      lng: project.address_lon,
      address: project.address,
      marker_uid: this.marker_uid,
      icon: this.markerImage,
      animation: google.maps.Animation.DROP,
    });
  }
}

export default MapGenerator;
