function transitionendListener() {
  this.setAttribute("hidden", "");
}
$(document).on('turbolinks:load', function() {


  const menu = document.querySelector(".menu");
  if (menu) {
    const hamburger = menu.querySelector(".hamburger");
    const menuList = menu.querySelector(".menu-items");
    const maxHight = menuList.clientHeight;

    menuList.style.height = "0px"; // initializing the list to zero hight
    menuList.classList.remove("invisible"); // the list is initially hidden, so when getting the height, this style is removed
    transitionendListener.call(menuList); // after getting the height of the list, set it to display:none

    function hamburgerHandler() {
      if (hamburger.classList.toggle("is-active")) {
        setTimeout(() => {
          $("body").one("click", (e) => {
            if (
              this !== e.target &&
              this !== $(e.target).parents("button.hamburger")[0]
            )
              hamburgerHandler();
          });
        }, 0);
        if (menuList.hasAttribute("hidden")) {
          menuList.removeAttribute("hidden");
        }
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (menuList.style.height = `${maxHight}px`))
        );
        menuList.removeEventListener("transitionend", transitionendListener);
      } else {
        menuList.style.height = 0;
        menuList.addEventListener("transitionend", transitionendListener);
      }
    }

    hamburger.addEventListener("click", hamburgerHandler);
  }
});


