/**
 * General utility functions shared among JS files
 */


const defaultTabProps = {
  selector: undefined,
  getCloseBTN: (container) =>
    $(container.getAttribute("data-bs-target") + " .btn-close"),
  onClose: undefined,
  onShow: undefined,
  onHide: undefined,
  onShown: undefined,
  onHidden: undefined,
};

function initTabs(obj) {
  const tabProps = Object.assign(defaultTabProps, obj);

  const tabs = $(tabProps.selector);

  tabs.each((_, tab) => {
    const tabTrigger = new bootstrap.Tab(tab);

    tab.addEventListener("click", (event) => {
      event.preventDefault();
      tabTrigger.show();
    });

    tab.addEventListener("shown.bs.tab", (e) => {
      // because there are different tabs groups in <nav>, its imperative to turn off other that are "active"
      tabs.each((_, el) => {
        if (el !== e.target && el.classList.contains("active")) {
          el.classList.remove("active");
        }
      });

      const newCloseBTN = tabProps.getCloseBTN(e.target);
      const oldCloseBTN = tabProps.getCloseBTN(e.relatedTarget);

      // registering close-button event listener
      if (newCloseBTN) {
        newCloseBTN.on("click", tabProps.onClose);
      }

      // un-registering close-button event listener
      if (newCloseBTN && oldCloseBTN && newCloseBTN[0] !== oldCloseBTN[0]) {
        oldCloseBTN.off();
      }

      if (tabProps.onShown) tabProps.onShown(e);
    });

    if (tabProps.onShow) tab.addEventListener("show.bs.tab", tabProps.onShow);
    if (tabProps.onHide) tab.addEventListener("hide.bs.tab", tabProps.onHide);
    if (tabProps.onHidden)
      tab.addEventListener("hidden.bs.tab", tabProps.onHidden);
  });
}

export default initTabs;
