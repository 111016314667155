import { setSafeBounds, getUrlParameter } from "javascripts/functions.js";
import MapGenerator from "javascripts/map_generator";

$(document).on("turbolinks:load", function () {
  if ($("#map").length > 0) {
    const styles = [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#333833",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#99dbb0",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#bfbfbf",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#424242",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#dadada",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#adaeae",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#313030",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#c7c7c7",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#424242",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#c9c9c9",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#a1d3f2",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
    ];

    const map = (window.map = new MapGenerator({
      selector: "#map",
      styles,
      center: {
        lat: 32.091991,
        lng: 34.787244,
      },
      clickHandler: function (marker) {
        if (marker.source == "inside") {
          var url = "/projects/" + marker.projectId + "?";
          // var link_opening = '<a href="' + url
          window.location.href = url;
        } else {
          var url =
            "/entrepreneur/" +
            marker.entrepreneur_id +
            "/outside_project/" +
            marker.projectId ; //+
            //'"';
          // var link_opening = '<a data-remote="true" href="' + url
          $.ajax({
            url: url,          
            dataType: "script",
            type: "GET",
          });
        }
        //window.location.href = url;        
      },
    }));

    map.addMarkersWithInfoWindow("#projects-list");

    map.fitZoom();

    var first_load = true;

    map.addListener("idle", function () {
      if (!first_load && $("#map-tab").hasClass("active")) {
        map.refresh();
        $("#place_id").val("");
      }

      first_load = false;
    });
  }

  $(".search-form .search-filter").val(
    getUrlParameter("autocomplete_search_address")
  );

  var search_params = $("#right-sidebar").data("session");

  if ($("#right-sidebar").length > 0 && search_params != null) {
    if (getUrlParameter("search_l")) {
      setSafeBounds(getUrlParameter("search_l"), map);
    } else {
      setSafeBounds(search_params["l"], map);
    }
  }

  $("#map-search-project").on("click", function () {
    $.ajax({
      url: '/is_signed_id',
      dataType: "json",
      type: "GET"
    }).done(function(data) {
      if (data.returnvalue === false)
        $('[data-bs-target="#login-reelook"]')[0].click();
      else {
        if (
          $("#results .selected").length > 0 &&
          !$("#results .selected").hasClass("pac-item-error")
        ) {
          $("#results .selected")[0].click();
        } else {
          if (!first_load && $("#map-tab").hasClass("active")) {
            map.refresh();
          }
          else
            window.location.href = "/projects";
            // Turbolinks.visit("/projects");
        }
      }
    })
    .fail(function() {
      callback("error");
    });
  });


  //   $('[data-bs-target="#login-reelook"]')[0].click();

  //  if (
  //     $("#results .selected").length > 0 &&
  //     !$("#results .selected").hasClass("pac-item-error")
  //   ) {
  //     $("#results .selected")[0].click();
  //   } else {
  //     if (!first_load && $("#map-tab").hasClass("active")) {
  //       map.refresh();
  //     }
  //     else
  //       Turbolinks.visit("/projects");
  //   }
  // });
});
