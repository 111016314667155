import { getUrlParameter, cesiumFlyToLocation } from "javascripts/functions.js";
//import MapGenerator from "javascripts/map_generator";


let proj3dData = {};

let isShowOSM = true;


// $(document).on("turbolinks:load", function () {
$( document ).ready(function() {
  
  if ($("#cesiumContainer").length > 0) {

     $.ajax({
      url: '/proj_3d_json',
      dataType: "json",
      type: "GET"
    }).done(function(data) {
      if (data)
      proj3dData = data;
      init3DTilesProjects();      
    }) 
    
    let element = $("#cesiumContainer")[0];
    
    Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjMjUyMWY5Ny1lYzhjLTQ1YTItYjAwMC00MDhkMGU2MDBmMmQiLCJpZCI6NTc3MzYsImlhdCI6MTY0MDk0NTgxMH0.HdefxK6XJQxh9XiHzPI7_182St9jRsJS9OfHKhURk30';    

    const viewer = (window.cesiumMap = new Cesium.Viewer('cesiumContainer', {
      scene3DOnly: true,
      selectionIndicator: false,
      baseLayerPicker: false,
      animation: false,
      creditsDisplay: false,
      creditsdisplay: false,
      CreditsDisplay: false,
      Creditsdisplay: false,
      //timeLine: false,
      //TimeLine: false,
      timeline: false,
      //Timeline: false,
      fullscreenButton: false,
      geocoder: false,
      homeButton: false,
      geocoder: false,
      navigationHelpButton: false,
      infoBox: false,
      //shadows: true,
      terrainShadows: Cesium.ShadowMode.ENABLED,

      imageryProvider : new Cesium.OpenStreetMapImageryProvider({
        url : 'https://a.tile.openstreetmap.org/'
      }),
      
      
      
      terrainProvider: Cesium.createWorldTerrain({
        requestVertexNormals: true,
        requestWaterMask: true
      })
    }));

    // var shadowMap = viewer.shadowMap;
    // shadowMap.maximumDistance = 10000.0;
    // shadowMap.softShadows = true;
    // shadowMap.size = 2048;



    //var layer = viewer.imageryLayers.addImageryProvider(
    //  new Cesium.IonImageryProvider({ assetId: 4 })
    //);
    
    //disable OSM outline for buildings
    Cesium.ModelOutlineLoader.hasExtension = function() { return false; }


    // Add Cesium OSM Buildings.
    const buildingsTileset = viewer.scene.primitives.add(Cesium.createOsmBuildings());
    
    // buildingsTileset.style = new Cesium.Cesium3DTileStyle({    
    //   color: {
    //     conditions: [          
    //       ["true", "color('white', 0.7)"], // This is the else case
    //     ],
    //   },
    // });

    


    
    // Fly the camera at the given longitude, latitude, and height.
    viewer.scene.camera.setView({
      destination: Cesium.Cartesian3.fromDegrees(34.790000, 32.077600, 250),
      orientation: {
        heading: Cesium.Math.toRadians(-32),
        pitch: Cesium.Math.toRadians(-20),
      },
    });
    

    // viewer.camera.flyTo({
    //   destination: Cesium.Cartesian3.fromDegrees(34.787244, 32.091991, 4000)
    // });

    // //check if needed
    // viewer.infoBox.frame.setAttribute('sandbox',      
    //   'allow-same-origin allow-scripts allow-popups allow-forms'      
    // );
    // viewer.infoBox.frame.removeAttribute('sandbox');
    function initOSMLayer() {
      buildingsTileset.style = new Cesium.Cesium3DTileStyle({
        // Create a style rule to control each building's "show" property.
        show: {
          evaluate : function(feature) {
            if (proj3dData.hasOwnProperty('elementToHide'))
            if (proj3dData['elementToHide'].includes(feature.getProperty('elementId'))) {
              console.log(feature.getProperty('elementId')); 
              return false;              
            }
            else {
              return true;
            }
          }
          
        },
        color: {
          conditions: [          
            ["true", "color('white', 0.8)"], // This is the else case #ffffff
          ]
        }        
      });
    }

    initOSMLayer();

    var tileset = null;
    
    // var array = [722329, 720950, 720949,720919,720916,720908,720742,720741,720737,720736,720322,
    //             720319,720309,720307 ,720303 ,714875 ,478178];
    function init3DTilesProjects() {
      Object.keys(proj3dData).forEach(key => { 
        tileset = viewer.scene.primitives.add(
          new Cesium.Cesium3DTileset({
              url: Cesium.IonResource.fromAssetId(key),
              //maximumScreenSpaceError: 16,
              //maximumMemoryUsage: 512
          })
        );
      })
    }
    // for (var index = 0; index < array.length; index++) {
    //     tileset = viewer.scene.primitives.add(
    //         new Cesium.Cesium3DTileset({
    //             url: Cesium.IonResource.fromAssetId(array[index]),
    //             //maximumScreenSpaceError: 16,
    //             //maximumMemoryUsage: 512
    //         })
    //     );
    // }

      // If the mouse is over the billboard, change its scale and color
    var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
    handler.setInputAction(function (movement) {
      var pickedObject = viewer.scene.pick(movement.position);
      if (Cesium.defined(pickedObject)) {
        if ((pickedObject.primitive.isCesium3DTileset) && 
            !(pickedObject instanceof Cesium.Cesium3DTileFeature)){              
            var project_id = pickedObject.primitive._url.split('/')[3];  
            //console.log(project_id); 
            if (proj3dData.hasOwnProperty(project_id)){ 
              Turbolinks.visit(proj3dData[project_id]);   
            }
        }
      }
    }, Cesium.ScreenSpaceEventType.LEFT_DOWN);


        // If the mouse is over the billboard, change its scale and color
    handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
    handler.setInputAction(function(movement) {
      var pickedObject = viewer.scene.pick(movement.endPosition);
      if (Cesium.defined(pickedObject)) {
        if ((pickedObject.primitive.isCesium3DTileset) && 
          !(pickedObject instanceof Cesium.Cesium3DTileFeature)){ 
            element.style.cursor = 'pointer';
        }
        else {
          element.style.cursor = 'default';
        }
      }
      else {
        element.style.cursor = 'default';
      }       
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    viewer.selectedEntityChanged.addEventListener(function(entity){
      if ((entity) && (entity.name === 'GJ1')) {
        //viewer.selectedEntity = undefined;
      }
    });

    //gets true or false
    function toggleOSMLayer() {
      if (isShowOSM) {
        buildingsTileset.style = new Cesium.Cesium3DTileStyle({
          show: "false",
        });
        isShowOSM = false;
      }
      else {
        initOSMLayer();
        isShowOSM = true;
      }
    }

    viewer.scene.light = new Cesium.DirectionalLight({
      direction: viewer.scene.camera.directionWC,
    });

    viewer.scene.preRender.addEventListener(function (scene, time) {
      viewer.scene.light.direction = Cesium.Cartesian3.clone(
        viewer.scene.camera.directionWC,
        viewer.scene.light.direction
      );
    }); 

    $('#toggle_osm_btn').on("click" , function(){
      toggleOSMLayer();
    });

    // function cesiumFlyToLocation(l) {
    //   if (l) {
    //     var latlngs = l.split(',');
    //     // var southWest = new google.maps.LatLng(latlngs[0], latlngs[1]);
    //     // var northEast = new google.maps.LatLng(latlngs[2], latlngs[3]);
    //     // var bounds = new google.maps.LatLngBounds(southWest, northEast);
    //     viewer.camera.flyTo({
    //       destination : Cesium.Rectangle.fromDegrees(latlngs[0], latlngs[1], latlngs[2],latlngs[3])
    //     });
    //   }
    // }
    
    // export {
    //   cesiumFlyToLocation
    // }

  }


  
  


  /*
  if ($("#map").length > 0) {
   
    const map = (window.map = new MapGenerator({
      selector: "#map",
      styles,
      center: {
        lat: 32.091991,
        lng: 34.787244,
      },
      clickHandler: function (marker) {
        if (marker.source == "inside") {
          var url = "/projects/" + marker.projectId + "?";
          // var link_opening = '<a href="' + url
        } else {
          var url =
            "/entrepreneur/" +
            marker.entrepreneur_id +
            "/outside_project/" +
            marker.projectId +
            '"';
          // var link_opening = '<a data-remote="true" href="' + url
        }
        window.location.href = url;
      },
    }));

    map.addMarkersWithInfoWindow("#projects-list");

    var first_load = true;

    map.addListener("idle", function () {
      if (!first_load && $("#map-tab").hasClass("active")) {
        map.refresh();
        $("#place_id").val("");
      }

      first_load = false;
    });
  }

  $(".search-form .search-filter").val(
    getUrlParameter("autocomplete_search_address")
  );
  */
  var ce_search_params = $("#right-sidebar").data("session");

  if ($("#right-sidebar").length > 0 && ce_search_params != null) {
    if (getUrlParameter("search_l")) {    
      cesiumFlyToLocation(getUrlParameter("search_l"),window.cesiumMap);
    } else {     
      cesiumFlyToLocation(ce_search_params["l"],window.cesiumMap);
    }
  }


  /*

  $("#map-search-project").on("click", function () {
    $.ajax({
      url: '/is_signed_id',
      dataType: "json",
      type: "GET"
    }).done(function(data) {
      if (data.returnvalue === false)
        $('[data-bs-target="#login-reelook"]')[0].click();
      else {
        if (
          $("#results .selected").length > 0 &&
          !$("#results .selected").hasClass("pac-item-error")
        ) {
          $("#results .selected")[0].click();
        } else {
          if (!first_load && $("#map-tab").hasClass("active")) {
            map.refresh();
          }
          else
            Turbolinks.visit("/projects");
        }
      }
    })
    .fail(function() {
      callback("error");
    });
  });


  //   $('[data-bs-target="#login-reelook"]')[0].click();

  //  if (
  //     $("#results .selected").length > 0 &&
  //     !$("#results .selected").hasClass("pac-item-error")
  //   ) {
  //     $("#results .selected")[0].click();
  //   } else {
  //     if (!first_load && $("#map-tab").hasClass("active")) {
  //       map.refresh();
  //     }
  //     else
  //       Turbolinks.visit("/projects");
  //   }
  // });*/
});
