$(document).on('turbolinks:load', function() {

    // $("#btn-create-meeting-pdf").on("click", function (event) {
    //     var createPDFurl = $(this).data('pdfurl');
    //     var summary_pdf_data = {};               
    //     summary_pdf_data['summary_notes'] = $("#meeting_sum_summary_notes").val();
    //     summary_pdf_data['summary_pdf_notes'] = $("#meeting_sum_summary_pdf_notes").val();
    //     summary_pdf_data['summary_rep_name'] = $("#meeting_sum_summary_rep_name").val();
    //     summary_pdf_data['summary_date'] = $("#meeting_sum_summary_date").val();
    //     summary_pdf_data['summary_dest_name'] = $("#meeting_sum_summary_dest_name").val();
       
    //     $.ajax({
    //       url: createPDFurl,
    //       data: JSON.stringify(summary_pdf_data),
    //       dataType: "json",
    //       type: "POST",
    //       contentType: "application/json; charset=utf-8",
    //       success: function(result){
    //         alert("pdf");
    //       }
    //     }).done(function(data) {        
    //       alert("pdf2");//Rails.fire($("#apartments-search-form")[0], "submit");     
    //     })   
    // });

    
    $("#btn-save-summary").on("click", function (event) {
        var saveSummaryUrl = $(this).data('saveurl');
        var summary_data = {};               
        summary_data['summary_notes'] = $("#meeting_sum_summary_notes").val();
        summary_data['summary_pdf_notes'] = $("#meeting_sum_summary_pdf_notes").val();
        summary_data['summary_rep_name'] = $("#meeting_sum_summary_rep_name").val();
        summary_data['summary_date'] = $("#meeting_sum_summary_date").val();
        summary_data['summary_dest_name'] = $("#meeting_sum_summary_dest_name").val();

        summary_data['summary_rep_phone'] = $("#meeting_sum_summary_rep_phone").val();
        summary_data['summary_rep_email'] = $("#meeting_sum_summary_rep_email").val();
        summary_data['summary_dest_phone'] = $("#meeting_sum_summary_dest_phone").val();
        summary_data['summary_dest_email'] = $("#meeting_sum_summary_dest_email").val();
        summary_data['summary_is_pdf'] = $("#btn-add-sum-pdf-notes").prop("checked")

        summary_data['summary_apartment_ids'] = $("#btn-summary_ap_ids").data("sumapids");
        $(".loader_container_div").css("display", "block");
        $(".loader_ajax_summary").css("display", "block");
        $.ajax({
          url: saveSummaryUrl,
          data: JSON.stringify(summary_data),
          dataType : 'script',
          type: $(this).data('method'),
          contentType: "application/json; charset=utf-8",
          success: function(result){
            alert("נשמר בהצלחה");
          }
        }).done(function(projects) {
          $(".loader_container_div").css("display", "none");
          $(".loader_ajax_summary").css("display", "none");
          // $(".copy-to-clipboard").on("click", function () {
          //   var copyText = document.querySelector(".modal.show .project-url");
          //   copyText.select();
          //   copyText.setSelectionRange(0, 99999);
          //   document.execCommand("copy");
          //   alert("הטקסט הועתק!");
          // });
          window.open($("#btn-save-summary").data("saveurl"), '_blank');

        })
    });

    $("#btn-add-sum-notes").on("click", function (event) {
      $('#meeting_sum_summary_notes').prop('disabled',!$("#btn-add-sum-notes").is(':checked'));
      // if ($("#input-add-sum-notes").is(":hidden")) {
      //   $("#input-add-sum-notes").slideToggle();
      //}
    });

    
    $("#btn-add-sum-pdf-notes").on("click", function (event) {
      $('#meeting_sum_summary_pdf_notes').prop('disabled',!$("#btn-add-sum-pdf-notes").is(':checked'));
      $('#btn-sum-pdf-create').prop('disabled',!$("#btn-add-sum-pdf-notes").is(':checked'));
      // if ($("#input-add-sum-pdf-notes").is(":hidden")) {
      //   $("#input-add-sum-pdf-notes").slideToggle();
      //   $("#btn-sum-pdf-create").slideToggle();        
    });
    


    window.update_summary_ap_ids = function update_summary_ap_ids(ap_check_id) {      
      var ap_id = parseInt(ap_check_id.id.split('_')[3])
      var ap_idx_in_arr = $("#btn-summary_ap_ids").data("sumapids").indexOf(ap_id);
      if (ap_check_id.checked) {
        if (ap_idx_in_arr == -1) {        
          $("#btn-summary_ap_ids").data("sumapids").push(ap_id);
        }
      } else {
        if (ap_idx_in_arr != -1) {
          $("#btn-summary_ap_ids").data("sumapids").splice(ap_idx_in_arr,1);
        }
      }

      //$("#arr-summary_ap_ids").val(JSON.stringify($("#btn-summary_ap_ids").data("sumapids")));
      $("#arr-summary_ap_ids").val($("#btn-summary_ap_ids").data("sumapids"));
    };

    
  //   $(".copy-to-clipboard").on("click", function () {
  //     var copyText = document.querySelector(".modal.show .project-url");
  //     copyText.select();
  //     copyText.setSelectionRange(0, 99999);
  //     document.execCommand("copy");
  //     alert("הטקסט הועתק!");
  //  });


});
