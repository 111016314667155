import Rails from "@rails/ujs";

$(document).on("turbolinks:load", function () {
  const apartmentBtnElem = document.querySelector(".apartment-table-btn");
  //let previousTab;

  // const showHandler = (event) => {
  //   previousTab = event.relatedTarget;
  //   event.target.removeEventListener("show.bs.tab", showHandler);
  //   $(apartmentBtnElem).addClass("active");
  // };

  // const hideHandler = (event) => {
  //   previousTab = undefined;
  //   event.target.removeEventListener("hide.bs.tab", hideHandler);
  //   $(apartmentBtnElem).removeClass("active");
  // };

  /**
   * this function defines the "Buying request" button's behaviors, which are:
   * 1.1: clicking this button -> opens the "apartments" tab.
   * 1.2: clicking this button again -> returns to the previous tab.
   * 2.1: clicking this button while the "apartments" tab is open ->
   * refreshes the "apartments" tab and colors this button.
   * (as if the "apartments" tab was not open)
   * 2.2: clicking this button again -> return to the "3d-model" tab.
   * 3.1: clicking this button and navigating to other tabs ->
   * restores this button's state and color.
   * 
   */
  $(apartmentBtnElem).on("click", function () {
    var apartmentsElem = document.getElementById("apartments-tab");

    $(".show-ap-tab.detailed-content").removeClass("d-flex");

    // listening for apartments-tab visibility.
    // not using inline functions to prevent re-registering the same events
    //apartmentsElem.addEventListener("hide.bs.tab", hideHandler);

    // if clicking "this" button again, restoring the previous active tab
    //if (previousTab) {
    //  apartmentBtnElem.removeEventListener("show.bs.tab", showHandler);
    //  bootstrap.Tab.getInstance(previousTab).show();
    //} else if ($(apartmentsElem).hasClass("active")) {
    if ($(apartmentsElem).hasClass("active")) {
      //$(this).addClass("active");
      //previousTab = document.getElementById("model-tab");
    } else {
      //apartmentsElem.addEventListener("show.bs.tab", showHandler);
      bootstrap.Tab.getInstance(apartmentsElem).show();
      Rails.fire($("#apartments-search-form")[0], "submit");
    }

  });
});
